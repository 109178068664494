import { ClockIcon } from "@heroicons/react/24/outline";
import { StarIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IEvalByYear } from "../../types/interfaces/IEvalByYear";
import {
  IEvaluationTemplate,
  IPerformanceData,
  IPerformanceForm,
} from "../../types/interfaces/IPerformance.interface";
import PerFormanceRating from "../performance/PerFormanceRating";
import PerformanceHistory from "../performance/PerformanceHistory";

interface Props {
  evalFormData?: IPerformanceForm;
  evalTemplateData?: IEvaluationTemplate;
  selectedEmployeeId: IPerformanceData;
  evalYearData?: IEvalByYear;
  year?: number;
  setYear: Function;
  edit: boolean;
  performanceData: any;
  discardBtn: Function;
  update: Function;
  saveResult: Function;
  history: boolean;
  setHistory: Function;
  getPerformanceData: Function;
  getEvalByYearUser: Function;
  getEmpEvalForm: Function;
  inEditForm: boolean;
  getTotalEmp: Function;
}

const PerformanceForm: React.FC<Props> = ({
  evalFormData,
  evalTemplateData,
  selectedEmployeeId,
  evalYearData,
  year,
  setYear,
  edit,
  performanceData,
  discardBtn,
  update,
  saveResult,
  history,
  setHistory,
  getPerformanceData,
  getEvalByYearUser,
  getEmpEvalForm,
  inEditForm,
  getTotalEmp,
}) => {
  const [evalData, setEvalData] = useState<any>(evalFormData?.EvaluationPoints);
  const [initialEvalData, setInitialEvalData] = useState<any>(
    evalFormData?.EvaluationPoints
  );

  const saveEvalResult = async () => {
    const res = await saveResult(evalData, selectedEmployeeId.userId);
    // setInitialEvalData(evalData)
  };
  const updateResult = async () => {
    await update(evalFormData?.ID || "", evalFormData?.UserId || "", evalData);
  };

  const historyClicked = () => {
    setHistory(true);
  };
  const ratePerformanceClicked = () => {
    setHistory(false);
  };

  useEffect(() => {
    setEvalData(evalFormData);
    setInitialEvalData(evalFormData);
  }, [evalFormData, setInitialEvalData]);

  return (
    <>
      <div className="mb-4 flex sm:flex-row flex-col gap-3 sm:justify-between items-start sm:items-center w-full">
        <h2 className="text-xl text-[#333333] font-semibold">
          Performance Management
        </h2>

        <div>
          <ul className="flex items-center gap-2">
            <li>
              <Link
                onClick={ratePerformanceClicked}
                to="#"
                className={`flex items-center gap-1 pe-3 sm:px-3 py-2 text-[#ADADAD] text-xs font-semibold ${
                  history === false
                    ? "text-black bg-[#EBEDEF] rounded"
                    : "hover:text-[#33475F] hover:bg-[#EBEDEF] hover:rounded"
                }`}
              >
                <StarIcon className="w-4 h-4 text-black" /> Rate Performance
              </Link>
            </li>
            <li>
              <Link
                onClick={historyClicked}
                to="#"
                className={`flex items-center gap-1 pe-3 sm:px-3 py-2 text-[#ADADAD] text-xs font-semibold ${
                  history === true
                    ? "text-black bg-[#EBEDEF] rounded"
                    : "hover:text-[#33475F] hover:bg-[#EBEDEF] hover:rounded"
                }`}
              >
                <ClockIcon className="w-4 h-4 text-black" />
                History
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="border-[#EBEBEB] border rounded-2xl p-3 bg-white mb-5 h-[calc(100vh-400px)] lg:h-[calc(100vh-350px)]">
        {selectedEmployeeId.userId ? (
          history === false || edit === true ? (
            <PerFormanceRating
              evalTemplateData={evalTemplateData}
              evalFormData={evalFormData}
              selectedEmployeeId={selectedEmployeeId}
              evalData={setEvalData}
            />
          ) : (
            <PerformanceHistory
              evalYearData={evalYearData}
              year={year as number}
              setYear={setYear}
              setHistory={setHistory}
              getPerformanceData={getPerformanceData}
              getEvalByYearUser={getEvalByYearUser}
              getEmpEvalForm={getEmpEvalForm}
              getTotalEmp={getTotalEmp}
            />
          )
        ) : (
          <div className="text-center flex flex-col justify-center h-full">
            <span className="text-center flex justify-center mb-3">
              <img src="/images/no-data.png" alt="Please Select any Employee" />
            </span>
            <p className="text-lg font-normal text-[#333333]">
              Please select any employee to see performance details or edit them
              {/* click on Add Employee button to add an employee */}
            </p>
          </div>
        )}
      </div>

      {!history && selectedEmployeeId.userId && (
        <div className="flex items-center gap-2 justify-end">
          <button
            onClick={() => discardBtn()}
            className="text-sm font-semibold text-[#378EB5] border border-[#378EB5] rounded-lg px-4 py-2 hover:bg-[#0891B2] hover:text-white"
          >
            Discard
          </button>
          {inEditForm ? (
            <button
              className="bg-[#0891B2] text-[#FAFAFA] text-sm px-4 py-2 rounded-lg font-semibold hover:bg-[#5a9dad] "
              onClick={() => updateResult()}
            >
              Update
            </button>
          ) : (
            <button
              className="bg-[#0891B2] text-[#FAFAFA] text-sm px-4 py-2 rounded-lg font-semibold hover:bg-[#5a9dad] "
              onClick={() => saveEvalResult()}
            >
              Save
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default PerformanceForm;
