import { InboxIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import LunchStatus from "../../components/Employeestatus/LunchStatus";
import LunchSubscriptionTable from "../../components/Table/LuchSubscriptiontable";
import { fetchLunchSubsInfo } from "../../services/lunchManagement.service";
import { ILunchSubsInfo } from "../../types/interfaces/ILunchSubs.interface";
import { formatAmount } from "../../utils/dateFromatter";

const LunchMangement = () => {
  const [lunchSubsInfo, setLunchSubsInfo] = useState<ILunchSubsInfo>();
  const [lunchRate, setLunchRate] = useState<number>(0);

  const getLunchSubsInfo = async () => {
    const res: any = await fetchLunchSubsInfo();
    if (res?.res) {
      setLunchSubsInfo(res?.res as ILunchSubsInfo);
      setLunchRate(res?.res.amountOfWeeklyLunchSubscription);
    }
  };
  const currency = localStorage.getItem("currency");

  useEffect(() => {
    getLunchSubsInfo();
  }, []);

  return (
    <>
      <div className="basis-full px-[15px] xl:px-[30px]">
        <LunchStatus
          currency={currency}
          showFirst={true}
          showSecond={true}
          heading="Lunch Subscription"
          leadText="Manage lunch subscriptions of employees"
          StatusLogo={<InboxIcon className="w-9 h-9 text-[#33475F]" />}
          Subscription="Subscription Amount"
          price={formatAmount(lunchRate ?? "0" + " PKR/Week")}
          TotalSubscriptions="Total Subscriptions"
          Total={lunchSubsInfo?.totalSubscribed}
          getLunchSubsInfo={getLunchSubsInfo}
        />
        <div className="basis-2/3">
          <LunchSubscriptionTable getLunchSubsInfo={getLunchSubsInfo} />
        </div>
      </div>
    </>
  );
};

export default LunchMangement;
