import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { IEmpReports } from "../../types/interfaces/IEmpRports";

interface Props {
  reportsData: IEmpReports;
  selectedReport?: string;
  setSelectedReport?: (name: string) => void;
}

const EmpReportsItem: React.FC<Props> = ({
  reportsData,
  selectedReport,
  setSelectedReport,
}) => {
  const [searchReport, setSearchReport] = useState("");

  const filteredReports = reportsData.reportList.filter((report: any) =>
    report.toLowerCase().includes(searchReport.toLowerCase())
  );

  return (
    <div>
      <h2 className="text-xl text-[#333333] mb-4 font-semibold">Reports</h2>
      <div className="relative flex items-center mb-4">
        <input
          className="cursor-text w-full py-[10px] ps-10 pe-3 border border-[#D6D6D6]  bg-[#ffffff] rounded-lg focus:outline-none"
          type="search"
          placeholder="Search"
          value={searchReport}
          onChange={(event) => setSearchReport(event?.target?.value)}
        />
        <span className="absolute top-3 left-3">
          <MagnifyingGlassIcon className="w-5 h-5 text-[#858585]" />
        </span>
      </div>
      <div className="Reports border-[#EBEBEB] border rounded-2xl p-3 bg-white overflow-y-scroll md:h-[calc(100vh-444px)] h-[calc(100vh-615px)]">
        {filteredReports.map((report: any, index: any) => (
          <div
            key={index}
            className={`flex items-center justify-between hover:bg-[#F0F7FB] rounded-md py-4 px-3 cursor-pointer ${
              selectedReport === report && "bg-[#F0F7FB]"
            }`}
            onClick={() => setSelectedReport && setSelectedReport(report)}
          >
            <div className="flex items-center gap-3">
              <p className="text-sm text-[#5C5C5C] w-6">{index + 1}</p>
              <div>
                <p className="text-sm text-[#33475F] font-semibold">{report}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmpReportsItem;
