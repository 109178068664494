import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import EmployeeStatus from "../../components/Employeestatus/EmployeeStatus";
import LeaveMangemnetTable from "../../components/leavemanagement/LeaveMangemnetTable";
import { ICurrentDayLeave } from "../../types/interfaces/ILeaveRecords.interface";

import moment from "moment";
import {
  fetchCurrentDayLeaveInfo,
  fetchPendingRequest,
} from "../../services/leave.service";

const LeaveManagement = () => {
  const [currentDayInfo, setCurrentDayInfo] = useState<ICurrentDayLeave>();
  const [empsOnLeaveToday, setEmpsOnLeaveToday] = useState<number>(0);
  const [pendingLeaves, setPendingLeaves] = useState<number>(0);
  const [openPopoverId, setOpenPopoverId] = useState<string | null>(null);

  const getCurrentDayLeaveInfo = async () => {
    const res = await fetchCurrentDayLeaveInfo();
    if (res?.res) {
      setCurrentDayInfo(res?.res as ICurrentDayLeave);
    }
    const getAllPending = await fetchPendingRequest(
      moment().format("DD/MM/YYYY")
    );
    if (getAllPending) {
      setPendingLeaves(getAllPending?.res?.Leaves?.length as number);
    }
  };

  useEffect(() => {
    getCurrentDayLeaveInfo();
  }, []);

  useEffect(() => {
    if ((currentDayInfo?.Leaves?.length as number) < 1) {
      setEmpsOnLeaveToday(0);
      return;
    }
    const onLeave = currentDayInfo?.Leaves?.filter(
      (record) => record?.Status === "accepted"
    );
    const onPending = currentDayInfo?.Leaves?.filter(
      (record) => record?.Status === "pending"
    );
    setEmpsOnLeaveToday(onLeave?.length as number);
  }, [currentDayInfo]);

  return (
    <div
      onClick={() => {
        openPopoverId && openPopoverId?.length > 0 && setOpenPopoverId(null);
      }}
      className="basis-full px-[15px] xl:px-[30px]"
    >
      <div className="">
        <EmployeeStatus
          showFirst={true}
          showSecond={true}
          heading="Leaves"
          leadText="Manage leaves of employees"
          StatusLogo={<CalendarDaysIcon className="w-9 h-9 text-[#33475F]" />}
          Subscription="Total Employees on leave"
          price={empsOnLeaveToday}
          TotalSubscriptions="Pending Requests"
          Total={pendingLeaves}
        />
        <div className="basis-2/3">
          <LeaveMangemnetTable
            getCurrentDayLeaveInfo={getCurrentDayLeaveInfo}
            openPopoverId={openPopoverId}
            setOpenPopoverId={setOpenPopoverId}
          />
        </div>
      </div>
    </div>
  );
};

export default LeaveManagement;
