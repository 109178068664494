import { NewFuel } from "../types/interfaces/FuelData";
import { api } from "../utils/axios";

export const fetchPendingFuelInfo = async () => {
  try {
    const { data, ok } = await api.get(`/fuelrequest/FindPendingCount`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
export const fetchAllFuels = async (
  month?: string,
  year?: string,
  search?: string
) => {
  try {
    const { data, ok } = await api.get(
      `/fuelrequest/FindAll?${search ? "search=" + search : ""}${
        month ? "&month=" + month + "&year=" + year : ""
      }`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const exportFuelCSV = async () => {
  try {
    const { data, ok } = await api.post(`/fuelrequest/ExportCSV`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const getLatestFuelPrice = async () => {
  try {
    const { data, ok } = await api.get("/fuelrequest/LatestRate");
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const addLatestFuelRate = async (reqBody: NewFuel) => {
  console.log("API", reqBody);
  try {
    const { data, ok } = await api.post(
      "/fuelrequest/AddNewFuelRates",
      reqBody
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const getAllFuelRates = async () => {
  try {
    const { data, ok } = await api.get("/fuelrequest/AllFuelRates");
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
