import {
  ICurrentPending,
  IUpdateLeaveStatus,
} from "../types/interfaces/ILeaveRecords.interface";
import { api } from "../utils/axios";

export const fetchAllLeave = async (
  startDate?: string,
  endDate?: string,
  search?: string
) => {
  try {
    const { data, ok } = await api.get(
      `/leaverequest/FindAll?${search ? "search=" + search : ""}${
        startDate ? "&startDate=" + startDate + "&endDate=" + endDate : ""
      }`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
export const fetchPendingLeave = async (
  startDate?: string,
  endDate?: string,
  search?: string
) => {
  try {
    const { data, ok } = await api.get(
      `/leaverequest/FindPending?${search ? "search=" + search : ""}${
        startDate ? "&startDate=" + startDate + "&endDate=" + endDate : ""
      }`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const fetchCurrentDayLeaveInfo = async () => {
  try {
    const { data, ok } = await api.get(`/leaverequest/findLeavesDay`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const fetchPendingRequest = async (date: string) => {
  try {
    const { data, ok } = await api.get<ICurrentPending>(
      `/leaverequest/FindPending?currentDate=${date}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
export const updateLeaveStatus = async (reqBody: IUpdateLeaveStatus) => {
  try {
    const { data, ok } = await api.put(`/leaverequest/UpdateStatus`, reqBody);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const exportLeaveCSV = async () => {
  try {
    const { data, ok } = await api.post(`/leaverequest/ExportCSV`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
