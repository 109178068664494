import { XMarkIcon } from "@heroicons/react/24/solid";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import ViewSlider from "../viewAttachmentSlider/ViewSlider";

interface ViewAttachmentsProps {
  isOpen: boolean;
  onClose: () => void;
  attachments: string[];
}

const ViewAttachments: FC<ViewAttachmentsProps> = ({
  isOpen,
  onClose,
  attachments,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<Slider>(null);

  const handleClose = () => {
    setCurrentSlide(0);
    onClose();
  };

  useOnClickOutside(ref, handleClose);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "ArrowLeft") {
        sliderRef.current?.slickPrev();
      } else if (e.key === "ArrowRight") {
        sliderRef.current?.slickNext();
      }
    },
    [sliderRef]
  );
  // useEffect(() => {
  //   if (isOpen) {
  //     document.addEventListener("keydown", handleKeyDown, true);
  //   } else {
  //     document.removeEventListener("keydown", handleKeyDown, true);
  //   }
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown, true);
  //   };
  // }, [isOpen, handleKeyDown]);

  useEffect(() => {
    if (!isOpen) {
      setCurrentSlide(0);
      sliderRef.current?.slickGoTo(0); // Ensure slider is reset to the first slide
    }
  }, [isOpen]);

  useEffect(() => {
    containerRef.current?.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentSlide]);

  return (
    <div
      className={`fixed inset-0 bg-gray-500 z-50 bg-opacity-75 flex items-center justify-center ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div
        className="bg-white rounded-lg shadow-md w-full max-w-4xl h-[90%] overflow-hidden"
        ref={ref}
      >
        <div className="flex justify-between items-center px-4 py-2">
          <h3 className="text-base font-semibold text-[#171717]">
            View Attachments
          </h3>
          <button onClick={handleClose}>
            <XMarkIcon className="w-10 h-10 text-[#737373]" />
          </button>
        </div>
        <hr />
        <div
          className="overflow-y-auto overflow-x-hidden h-[calc(100%-80px)]"
          ref={containerRef}
        >
          <ViewSlider
            attachments={attachments}
            currentSlide={currentSlide}
            setCurrentSlide={setCurrentSlide}
            sliderRef={sliderRef}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewAttachments;
