import { EventType, PublicClientApplication } from "@azure/msal-browser";
import "react-datepicker/dist/react-datepicker.css";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { msalConfig } from "./config/auth-config";
import reportWebVitals from "./reportWebVitals";
import { setIsAuthenticated } from "./services/auth.service";
import { store } from "./store/store";
import "./style.css";
export const msalInstance = new PublicClientApplication(msalConfig);

if (msalInstance.getActiveAccount()) {
  setIsAuthenticated(true);
}

if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event: any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event?.payload?.account) {
    const { account } = event.payload;
    msalInstance.setActiveAccount(account);
    setIsAuthenticated(true);
  }

  if (event.eventType === EventType.LOGOUT_SUCCESS) {
    if (msalInstance.getAllAccounts().length > 0) {
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }
  }

  if (event.eventType === EventType.LOGIN_FAILURE) {
    // TODO: Show appropriate error message here
  }
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <App msalInstance={msalInstance} />
  </Provider>
);

reportWebVitals();
