import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { updateLaunchPrice } from "../../services/lunchManagement.service";

interface Props {
  price: string;
  setShowPayModal: Function;
  getLunchSubsInfo: Function;
}

const UpdateSubscriptionPriceModel: React.FC<Props> = ({
  price,
  setShowPayModal,
  getLunchSubsInfo,
}) => {
  const ref: any = useRef();
  useOnClickOutside(ref, () => handleClose());

  const currency = localStorage.getItem("currency") || "USD"; // Default to USD if not found

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const parseCurrency = (value: string) => {
    return parseInt(value.replace(/[^0-9]+/g, ""), 10);
  };

  const [amount, setAmount] = useState<number | undefined>(
    parseCurrency(price)
  );

  const handleClose = () => {
    setAmount(parseCurrency(price));
    setShowPayModal(false);
  };

  const updateSubscriptionPrice = async () => {
    const res: any = await updateLaunchPrice(amount);
    if (res?.res) {
      toast.success(res?.res.message);
      setShowPayModal(false);
      getLunchSubsInfo();
    }
  };
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div ref={ref} className="bg-white rounded-lg p-5 w-[490px]">
        <div className="flex justify-between items-baseline">
          <h2 className="text-lg font-semibold mb-4">
            Update Subscription Amount
          </h2>
          <button onClick={handleClose}>
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Subscription Amount
          </label>
          <input
            className="bg-[#EBEBEB] w-64 text-sm text-black border border-[#D6D6D6] rounded-lg p-3 my-2"
            type="text"
            placeholder="Amount"
            value={amount !== undefined ? formatCurrency(amount) : ""}
            onChange={(event) => {
              const parsedAmount = parseCurrency(event.target.value);
              setAmount(!isNaN(parsedAmount) ? parsedAmount : undefined);
            }}
          />
        </div>
        <div className="flex justify-end gap-2">
          <button
            className="bg-gray-300 text-gray-700 rounded px-4 py-2"
            onClick={() => handleClose()}
          >
            Cancel
          </button>
          <button
            className="bg-[#378EB5] text-white rounded px-4 py-2"
            onClick={() => {
              updateSubscriptionPrice();
            }}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateSubscriptionPriceModel;
