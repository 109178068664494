import {
  IBankData,
  IEmployeePayload,
} from "../types/interfaces/IEmployeesManagement.interface";
import { api } from "../utils/axios";
import { Subject } from "rxjs";

export let resfresh$: any = new Subject();
export const fetchAllEmployees = async () => {
  try {
    const { data, ok } = await api.get(`/user/FindAll`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
export const generatePassword = async () => {
  try {
    const { data, ok } = await api.get(`/user/GeneratePassword`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
export const addRemoveEmployee = async (reqBody: {
  userID: number;
  statusToSet: string;
}) => {
  try {
    const { data, ok } = await api.patch(
      `/user/ActivateDeactivateUser/${reqBody?.userID}/${reqBody?.statusToSet}`
    );
    if (ok) {
      resfresh$.next(true);
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
export const createEmployee = async (reqBody: IEmployeePayload) => {
  try {
    const { data, ok } = await api.post(`/user/createUser`, reqBody);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
export const updateEmployee = async (id: string, reqBody: IEmployeePayload) => {
  try {
    const { data, ok } = await api.put(`/user/UpdateEmployee/${id}`, reqBody);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const getAllBankData = async () => {
  try {
    const { data, ok } = await api.get<IBankData>(`/user/GetBankDetails`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
