import { NewspaperIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import BankCertificate from "../../components/CertifictaesAndLetterItem/BankCertificate";
import CertificatesAndLettersItems from "../../components/CertifictaesAndLetterItem/CertificatesAndLetterItem";
import CertificateTable from "../../components/CertifictaesAndLetterItem/CertificateTable";
import InternCertificate from "../../components/CertifictaesAndLetterItem/InterCertificates";
import JobCertificate from "../../components/CertifictaesAndLetterItem/JobCeritificate";
import EmployeeStatus from "../../components/Employeestatus/EmployeeStatus";
import { fetchEligibleUserForBank } from "../../services/empReports.service";
import { IBankUSers } from "../../types/interfaces/IBankUsers";

const CertificatesAndLetters = () => {
  const [userData, setUserData] = useState<IBankUSers[] | []>([]);

  const [selectedReport, setSelectedReport] = useState(
    "Experience Certificate"
  );

  const fetchData = async () => {
    const res = await fetchEligibleUserForBank();
    setUserData((res?.res as IBankUSers[]) || []);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    console.log(selectedReport, "jfjf");
  }, []);

  const dummyData = {
    reportList: [
      "Experience Certificate",
      "Employment Letter For Bank Account",
      "Employment Letter For Job",
      "Internship Letter",
    ],
  };

  return (
    <>
      <div className="basis-full px-[15px] xl:px-[30px]">
        <EmployeeStatus
          heading="Certificates And Letters"
          leadText="Download Certificates And Letters of employee "
          StatusLogo={<NewspaperIcon className="w-9 h-9 text-[#33475F]" />}
          Subscription=""
          showFirst={""}
          showSecond={false}
          Request={``}
          PendingDate={""}
        />
        <div className="basis-full md:basis-2/3">
          <div className="flex flex-col md:flex-row mt-5 gap-3 xl:gap-5">
            <div className="basis-full md:basis-1/3">
              <CertificatesAndLettersItems
                title="Certificates And Letters "
                reportsData={dummyData}
                selectedReport={selectedReport}
                setSelectedReport={setSelectedReport}
              />
            </div>
            <div className="basis-full md:basis-2/3 relative">
              {selectedReport === "Employment Letter For Bank Account" ? (
                <BankCertificate getUserWithEmail={() => null} />
              ) : selectedReport === "Internship Letter" ? (
                <InternCertificate getUserWithEmail={() => null} />
              ) : selectedReport === "Employment Letter For Job" ? (
                <JobCertificate getUserWithEmail={() => null} />
              ) : (
                <CertificateTable getUserWithEmail={() => null} />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default CertificatesAndLetters;
