import AdminProfile from "../../components/Profile/AdminProfile";

const Profile = () => {
  return (
    <div className="basis-full px-[15px] xl:px-[30px]">
      <div>
        <div className="basis-full md:basis-2/3">
          <AdminProfile />
        </div>
      </div>
    </div>
  );
};

export default Profile;
