import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import UpdateSubscriptionPriceModel from "../Modals/UpdateSubscriptionPrice";

const LunchStatus = ({
  heading,
  leadText,
  StatusLogo,
  Subscription,
  price,
  TotalSubscriptions,
  Total,
  Month,
  Request,
  showFirst,
  showSecond,
  PendingDate,
  currency,
  getLunchSubsInfo,
}: any) => {
  const [showPayModal, setShowPayModal] = useState(false);

  return (
    <>
      <div className="flex flex-col lg:flex-row lg:justify-between items-start lg:items-center px-3 xl:px-5 py-3 sm:py-5 lg:py-8 bg-white rounded-2xl gap-3">
        <div className="flex items-center gap-3">
          <div className="bg-[#F0F7FB] p-2 rounded-xl">{StatusLogo}</div>
          <div>
            <h3 className="text-lg sm:text-2xl font-semibold text-[#33475F]">
              {heading}
            </h3>
            <p className="text-xs sm:text-sm text-[#33475F]">{leadText}</p>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-3">
          {showFirst && (
            <div className="bg-[#EBEDEF] rounded-2xl py-[10px] px-4 w-64 ">
              <p className="text-sm font-semibold text-[#5C6C7F]">
                {Subscription}
                {Subscription === "Subscription Amount" && (
                  <button
                    className="text-[#378EB5] p-2 mx-2"
                    onClick={() => setShowPayModal(true)}
                  >
                    <PencilSquareIcon className="w-4 h-4" />
                  </button>
                )}
                <span className="ms-3 text-xs font-semibold font-normal">
                  {Month}
                </span>
              </p>
              <div className="flex justify-between items-center">
                <span className="text-base font-normal text-[#33475F]">
                  {!isNaN(price)
                    ? currency + " " + price
                    : currency + " " + price}
                  <span className="text-base font-normal text-[#33475F]">
                    {Request}
                  </span>
                </span>
                <span className="text-sm font-semibold text-[#378EB5]">
                  {PendingDate}
                </span>
              </div>
            </div>
          )}

          {showSecond && (
            <div className="bg-[#EBEDEF] rounded-2xl py-[10px] px-4 w-64">
              <p className="text-sm font-semibold text-[#5C6C7F]">
                {TotalSubscriptions}
              </p>
              <h3 className="text-base font-semibold text-[#33475F]">
                {Total ?? 0}
              </h3>
            </div>
          )}
        </div>
      </div>

      {showPayModal && (
        <UpdateSubscriptionPriceModel
          price={price}
          setShowPayModal={setShowPayModal}
          getLunchSubsInfo={getLunchSubsInfo}
        />
      )}
    </>
  );
};

export default LunchStatus;
