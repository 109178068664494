import { AcademicCapIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import EmployeeStatus from "../../components/Employeestatus/EmployeeStatus";
import SchoolingTable from "../../components/Schooling/SchoolingTable";
import { fetchTotalSchooling } from "../../services/schooling.service";

const Schooling = () => {
  const [schooling, setSchooling] = useState<any>([]);
  const [pendingCount, setPendingCount] = useState<number>(0);

  const getSchoolingData = async (month?: any, year?: any) => {
    console.log("month", month);
    const res: any = await fetchTotalSchooling(month, year);

    if (res?.res) {
      setSchooling(res.res);
      const pendingRequests = res.res.filter(
        (request: any) => request.status === "pending"
      );
      setPendingCount(pendingRequests.length);
    }
  };

  useEffect(() => {
    getSchoolingData();
  }, []);

  return (
    <>
      <div className="basis-full px-[15px] xl:px-[30px]">
        <EmployeeStatus
          heading="School Allowance"
          leadText="Manage school allowance requests"
          StatusLogo={<AcademicCapIcon className="w-9 h-9 text-[#33475F]" />}
          Subscription="Pending Requests"
          price={pendingCount}
          showFirst={true}
          showSecond={false}
        />
        <div className="basis-2/3">
          <SchoolingTable
            schooling={schooling}
            schoolingFunction={getSchoolingData}
          />
        </div>
      </div>
    </>
  );
};

export default Schooling;
