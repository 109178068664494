import {
  ChatBubbleBottomCenterTextIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { CalendarDaysIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import {
  updateAdvSalaryStatus,
  updatePaymentStatus,
} from "../../services/advanceSalary.service";
import {
  ISalaryRecords,
  Salary,
} from "../../types/interfaces/ISalaryRecords.interface";
import {
  formatAmount,
  formatDateMontYear,
  formatDateMonth,
  formatDateSpace,
  formatDateYear,
} from "../../utils/dateFromatter";
import { scrollToTop } from "../../utils/tableScroller";
import ReasonPopOver from "../../widgets/ReasonPopOver";
import ApproveRejectModal from "../Modals/ApproveRejectModal";

interface Props {
  getSalariesCountInfo: () => void;
  openPopoverId: string | null;
  setOpenPopoverId: (id: string | null) => void;
  advSalaryRequests: ISalaryRecords;
  setAdvSalaryRequests: Function;
  getAllAdvSalary: Function;
}
const AdvanceSalaryTable: React.FC<Props> = ({
  getSalariesCountInfo,
  openPopoverId,
  setOpenPopoverId,
  advSalaryRequests,
  setAdvSalaryRequests,
  getAllAdvSalary,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showPayModal, setShowPayModal] = useState<boolean>(false);

  const [searchQuery, setSearchQuery] = useState("");

  const [comments, setComments] = useState("");
  const [reqAgain, setReqAgain] = useState(false);
  const [actionToPerform, setActionToPerform] = useState("");
  const [selectedRecord, setSelectedRecord] = useState<Salary>();
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [paymentStatus, setPaymentStatus] = useState<any>("");
  const [cuurentPayId, setCurrentPayId] = useState<string>("");
  // const [openPopoverId, setOpenPopoverId] = useState<string | null>(null);
  const [scrollOnDate, setScrollOnDate] = useState(false);
  let timeoutId: NodeJS.Timeout | null = null;
  let scrollToTopRef = useRef<HTMLTableSectionElement>(null);

  const handlePaymentModel = (id: string) => {
    setShowPayModal(true);
    setCurrentPayId(id);
  };

  const handlePayment = async () => {
    const resp = await updatePaymentStatus(cuurentPayId);
    if (resp) {
      setPaymentStatus(resp);
      setShowPayModal(false);
      toast.success("Payment Done Sucessfully");
    }
  };

  const handleAdvSalaryAction = async (advSalaryReqData: Salary) => {
    const reqBody = {
      comment: comments,
    };
    const res = await updateAdvSalaryStatus(
      actionToPerform,
      advSalaryReqData?.salaryRequest?.SalaryID,
      reqBody
    );
    if (res?.res) {
      setReqAgain(!reqAgain);
      if (actionToPerform === "rejected") {
        toast.success("Advance Salaray Request Rejected Successfully");
      } else {
        toast.success("Advance Salaray  Request Approved Successfully");
      }
      getSalariesCountInfo();
      closeModal();
    }
  };

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
  };

  const handleComments = (comments: string) => {
    setComments(comments);
  };

  const ref: any = useRef();
  useOnClickOutside(ref, () => setShowPayModal(false));

  useEffect(() => {
    if (!selectedDate) return;
    setReqAgain(!reqAgain);
    setScrollOnDate(!scrollOnDate);
  }, [selectedDate]);

  useEffect(() => {
    if (searchQuery) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        if (selectedDate) {
          getAllAdvSalary(
            formatDateMonth(selectedDate),
            formatDateYear(selectedDate),
            searchQuery
          );
        } else getAllAdvSalary(undefined, undefined, searchQuery);
      }, 1000);
    } else if (selectedDate) {
      getAllAdvSalary(
        formatDateMonth(selectedDate),
        formatDateYear(selectedDate)
      );
    } else {
      getAllAdvSalary();
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [reqAgain, searchQuery, paymentStatus]);

  const openModal = (event: any, record: Salary) => {
    setIsModalOpen(true);
    setActionToPerform(event?.target?.name);
    setSelectedRecord(record);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setComments("");
  };

  const togglePopover = (record: Salary) => {
    if (openPopoverId === record.salaryRequest.SalaryID) {
      setOpenPopoverId(null);
    } else {
      setOpenPopoverId(record.salaryRequest.SalaryID);
    }
    setSelectedRecord(record);
  };
  const handleClear = () => {
    // setDateRange([]);
    setSelectedDate(undefined);
    setReqAgain(!reqAgain);
  };

  useEffect(() => {
    scrollToTop(scrollToTopRef);
  }, [searchQuery, scrollOnDate]);
  const currency = localStorage.getItem("currency");

  const handleKeyDown = (e: any) => {
    e.preventDefault(); // Prevent all key presses
  };

  return (
    <>
      <div className="py-5">
        <div className="relative flex md:flex-row flex-col md:items-center mb-3 md:mb-6 gap-3">
          <input
            className="cursor-text w-full md:max-w-[310px] py-[10px] ps-10 pe-4  border border-[#D6D6D6] bg-[#ffffff] rounded-lg focus:outline-none placeholder:text-sm"
            type="search"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e?.target?.value)}
          />
          <span className="absolute top-3 left-3">
            <MagnifyingGlassIcon className="w-5 h-5 text-[#858585]" />
          </span>
          <div className="relative mb-3 md:mb-0 flex items-center gap-1 sm:gap-2 z-10">
            <ReactDatePicker
              className="cursor-pointer rounded-lg min-w-[230px] w-full bg-[#ffffff] border border-[#D6D6D6] text-sm py-3 px-5 placeholder:text-[#333] placeholder:font-medium"
              dateFormat="MMMM yyyy"
              placeholderText="Select Month"
              showMonthYearPicker
              selected={selectedDate}
              onChange={(date) => handleDateChange(date as Date)}
              preventOpenOnFocus
              onKeyDown={(e) => handleKeyDown(e)}
            />
            {selectedDate ? (
              <button className="absolute top-3 right-4" onClick={handleClear}>
                <XMarkIcon className="text-black w-5 h-5" />
              </button>
            ) : (
              <span className="absolute  top-3 right-4 pointer-events-none">
                <CalendarDaysIcon className="w-5 h-5 text-[#333]" />
              </span>
            )}
          </div>
        </div>
        {advSalaryRequests?.Salaries?.length > 0 ? (
          <>
            <div
              className="Employees md:px-0 overflow-scroll overflow-x-hidden h-[calc(100vh-325px)]"
              ref={scrollToTopRef}
            >
              <table className="capitalize leaveMange w-full">
                <thead className="sticky top-0">
                  <tr>
                    <th></th>
                    <th>Employee Name</th>
                    <th>Month</th>
                    <th>Amount</th>
                    <th>Reason</th>
                    <th>Payment Status</th>
                    <th>Date Applied</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {advSalaryRequests?.Salaries?.map((req, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td data-cell="Employee Name">{req?.userName}</td>
                      <td data-cell="Month">
                        {formatDateMontYear(
                          req?.salaryRequest?.Month,
                          req?.salaryRequest?.Year
                        )}
                      </td>
                      <td data-cell="Amount">
                        {currency} {formatAmount(req?.salaryRequest?.Amount)}
                      </td>
                      <td data-cell="Reason">
                        <div className="relative">
                          <button
                            onClick={() => togglePopover(req)}
                            className={`text-sm text-[#378EB5] font-semibold ${
                              openPopoverId &&
                              openPopoverId?.length > 0 &&
                              (openPopoverId === req.salaryRequest.SalaryID
                                ? ""
                                : "cursor-default")
                            }`}
                          >
                            <ChatBubbleBottomCenterTextIcon className="text-#378EB5 w-5 h-5" />
                          </button>
                          {/* {isPopoverOpen &&
                            selectedRecord?.salaryRequest.SalaryID ===
                              req.salaryRequest.SalaryID && (
                              <ReasonPopOver
                                reason={
                                  selectedRecord?.salaryRequest
                                    ?.Reason as string
                                }
                              />
                            )} */}
                          {openPopoverId === req.salaryRequest.SalaryID && (
                            <ReasonPopOver
                              reason={req.salaryRequest.Reason as string}
                              onClose={() => setOpenPopoverId(null)}
                            />
                          )}
                        </div>
                      </td>
                      <td>{req?.salaryRequest?.PaymentStatus}</td>
                      <td data-cell="Date Applied">
                        {formatDateSpace(
                          moment(req?.salaryRequest?.CreatedAt).format(
                            "DD/MM/YYYY"
                          )
                        )}
                      </td>
                      <td>
                        <div className="flex items-center justify-end gap-1">
                          {req?.salaryRequest?.PaymentStatus === "Unpaid" ? (
                            <>
                              <button
                                className="btnApprove"
                                name="paid"
                                onClick={() =>
                                  handlePaymentModel(req.salaryRequest.SalaryID)
                                }
                              >
                                Pay
                              </button>
                            </>
                          ) : (
                            ""
                          )}

                          {req?.salaryRequest?.Status === "pending" ? (
                            <>
                              <button
                                className="btnApprove"
                                name="accepted"
                                onClick={(event) => openModal(event, req)}
                              >
                                Approve
                              </button>
                              <button
                                className="btnReject"
                                name="rejected"
                                onClick={(event) => openModal(event, req)}
                              >
                                Reject
                              </button>
                            </>
                          ) : req?.salaryRequest?.Status === "accepted" ? (
                            <span className="bg-[#23CDAF] text-white font-semibold text-[10px] rounded-[4px] px-2 py-0.5 uppercase">
                              Approved
                            </span>
                          ) : (
                            <span className="bg-[#333333] text-white font-semibold text-[10px] rounded-[4px] px-2 py-0.5 uppercase">
                              Rejected
                            </span>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* <TablePagination /> */}
            <ApproveRejectModal
              isOpen={isModalOpen}
              onClose={closeModal}
              comments={comments}
              handleComments={handleComments}
              recordData={selectedRecord}
              actionToPerform={actionToPerform}
              sendRequest={handleAdvSalaryAction}
            />
          </>
        ) : (
          <div className="text-center flex flex-col justify-center mt-16">
            <span className="text-center flex justify-center mb-3">
              <img src="/images/no-data.png" alt="no have data" />{" "}
            </span>
            <p className="text-sm font-normal text-[#5C5C5C]">
              No Advance Salary Request Available.
            </p>
          </div>
        )}
      </div>

      {showPayModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div ref={ref} className="bg-white rounded-lg p-5 w-[365px]">
            <h2 className="text-lg font-semibold mb-4">Confirm Payment </h2>
            <p className="mb-4">Are you sure you want to pay?</p>
            <div className="flex justify-end gap-2">
              <button
                className="bg-gray-300 text-gray-700 rounded px-4 py-2"
                onClick={() => setShowPayModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-[#378EB5] text-white rounded px-4 py-2"
                onClick={handlePayment}
              >
                Pay
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdvanceSalaryTable;
