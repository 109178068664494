import {
  CalendarDaysIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { LatestSlipDate } from "../../pages/paySlips/PaySlips";
import { Payroll } from "../../types/interfaces/IPaySlipData";
import { formatAmount } from "../../utils/dateFromatter";
import { scrollToTop } from "../../utils/tableScroller";
import GenratePayslipModal from "../Modals/GenratePayslipModal";

interface Props {
  setLatestSlipMonth: (obj: LatestSlipDate) => void;
  empData: Payroll[];
  getEmp: Function;
  calendarDate: string;
}

const PaySlipsTable: React.FC<Props> = ({
  setLatestSlipMonth,
  empData,
  getEmp,
  calendarDate,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const scrollToTopRef = useRef<HTMLTableSectionElement>(null);

  console.log("Calendar Date", calendarDate);
  function setCalendarDateFromString(monthYearString: string): Date | null {
    if (!monthYearString.trim()) {
      return null;
    }

    const [month, year] = monthYearString.split(" ");

    if (!month || !year) {
      return null;
    }

    const date = new Date(`${month} 1, ${year}`);

    if (isNaN(date.getTime())) {
      return null;
    }

    console.log("new Date", date);
    return date;
  }

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    scrollToTop(scrollToTopRef); 
  };

  useEffect(() => {
    scrollToTop(scrollToTopRef);
  }, [searchQuery]);

  const filteredPaySlip = empData?.filter((report: Payroll) =>
    report.employeeDetails.name
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  const [selectedDate, setSelectedDate] = useState<Date | null>(() =>
    setCalendarDateFromString(calendarDate)
  );
  const [reqAgain, setReqAgain] = useState(false);
  const [monthYear, setMonthYear] = useState<{ month: number; year: number }>();

  const handleClear = () => {
    setReqAgain(!reqAgain);
    setMonthYear(undefined);
    setSelectedDate(setCalendarDateFromString(calendarDate));
  };

  const dateHandler = (date: Date) => {
    setSelectedDate(date);
    const month = moment(date).month() + 1;
    const year = moment(date).year();
    setMonthYear({ month: month, year: year });
  };

  useEffect(() => {
    getEmp(monthYear?.month, monthYear?.year);
  }, [monthYear]);

  useEffect(() => {
    setSelectedDate(setCalendarDateFromString(calendarDate));
  }, [calendarDate]);
  const currency = localStorage.getItem("currency");

  const handleKeyDown = (e: any) => {
    e.preventDefault(); 
  };

  return (
    <>
      <div className="py-5">
        <div className="relative flex md:flex-row flex-col md:items-center md:justify-between mb-3 md:mb-6 gap-3">
          <div className="flex flex-col md:flex-row md:items-center gap-3">
            <div className="relative">
              <input
                className="cursor-text w-full md:max-w-[310px] py-[10px] ps-10 pe-4 border border-[#D6D6D6] bg-[#ffffff] rounded-lg focus:outline-none placeholder:text-sm"
                type="search"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e?.target?.value)}
              />
              <span className="absolute top-3 left-3">
                <MagnifyingGlassIcon className="w-5 h-5 text-[#858585]" />
              </span>
            </div>
            <div className="relative flex items-center z-10 gap-2">
              <ReactDatePicker
                className="cursor-pointer rounded-lg min-w-[230px] w-full bg-[#00000] border border-[#D6D6D6] text-sm py-3 px-5 placeholder:text-[#333] placeholder:font-medium"
                dateFormat="MMMM yyyy"
                placeholderText="Select Month"
                showMonthYearPicker
                maxDate={moment()
                  .month(new Date().getMonth() - 1)
                  .toDate()}
                selected={selectedDate}
                onChange={(date) => dateHandler(date as Date)}
                preventOpenOnFocus
                onKeyDown={(e) => handleKeyDown(e)}
                disabled={calendarDate === "N/A"}
              />
              {selectedDate ? (
                <button
                  className="absolute top-2.5 right-4"
                  onClick={handleClear}
                >
                  <XMarkIcon className="w-6 h-6" />
                </button>
              ) : (
                <span className="absolute top-3 right-4 pointer-events-none">
                  <CalendarDaysIcon className="w-5 h-5 text-[#333]" />
                </span>
              )}
            </div>
          </div>
          <button
            className="text-sm font-semibold text-white bg-[#378EB5] py-2.5 px-3 rounded-lg max-w-[135px]"
            onClick={openModal}
          >
            Generate Payroll
          </button>
          <GenratePayslipModal
            isOpen={isModalOpen}
            onClose={closeModal}
            getEmp={getEmp}
          />
        </div>

        {empData && empData.length > 0 ? (
          <div
            className="Employees md:px-0 overflow-scroll overflow-x-hidden h-[calc(100vh-335px)]"
            ref={scrollToTopRef}
          >
            <table className="capitalize leaveMange w-full">
              <thead className="sticky top-0">
                <tr>
                  <th></th>
                  <th>Employee Name</th>
                  <th>Designation</th>
                  <th>Basic Salary</th>
                  <th>Monthly Tax</th>
                  <th>EOBI Amount</th>
                  <th>Lunch Amount</th>
                  <th>Advance Salary</th>
                  <th>Total Net Salary</th>
                </tr>
              </thead>
              <tbody>
                {filteredPaySlip?.map((emp, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{emp?.employeeDetails.name}</td>
                    <td>{emp?.employeeDetails.designation}</td>
                    <td>
                      {currency}
                      {"  "}
                      {formatAmount(emp?.basicSalary)}
                    </td>
                    <td>
                      {currency}
                      {"  "}
                      {formatAmount(emp.deductions?.monthlyTax.toFixed(2))}
                    </td>
                    <td>
                      {currency}
                      {"  "}
                      {formatAmount(emp.deductions?.eobiAmount)}
                    </td>
                    <td>
                      {currency}
                      {"  "}
                      {formatAmount(emp.deductions?.lunchAmount)}
                    </td>
                    <td>
                      {currency}
                      {"  "}
                      {formatAmount(emp.deductions?.advanceSalary)}
                    </td>
                    <td className="font-bold">
                      {currency}
                      {"  "}
                      {formatAmount(emp.totalNetSalary)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="text-center flex flex-col justify-center mt-16">
            <span className="text-center flex justify-center mb-3">
              <img src="/images/no-data.png" alt="no data" />
            </span>
            <p className="text-sm font-normal text-[#5C5C5C]">
              No Payroll Available.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default PaySlipsTable;
