import { Subject } from "rxjs";
import { api } from "../utils/axios";
export const delteData$ = new Subject();

export const fetchSuggestionInfo = async () => {
  try {
    const { data, ok } = await api.get(`/suggestions/getSuggestions`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const fetchTotalSuggestion = async () => {
  try {
    const { data, ok } = await api.get(
      `/suggestions/getTotalAndApprovedSuggestions`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const postSuggestion = async (suggestionid: string, status: string) => {
  try {
    const { data, ok } = await api.post(
      `/suggestions/respondSuggestion?${
        suggestionid ? `suggestionid=${suggestionid}` : ""
      }&status=${status}`
    );
    if (ok) {
      delteData$.next(true);
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
