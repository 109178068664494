import { useRef } from "react";
import StarRatings from "react-star-ratings";

interface IProps {
  ratingValue: number;
}

const StartRating = (props: IProps) => {
  const refEl = useRef<StarRatings>(null);
  return (
    <StarRatings
      ref={refEl}
      rating={props.ratingValue}
      starRatedColor="#378EB5"
      starSpacing="2px"
      starDimension="16px"
      numberOfStars={5}
      name="rating"
    />
  );
};

export default StartRating;
