import { GrantBonus } from "../types/interfaces/IBonus";
import { api } from "../utils/axios";

export const fetchAllBonus = async (year?: string) => {
  try {
    const { data, ok } = await api.get(
      `/bonus/all${year ? "?year=" + year : ""}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const fetchEmployees = async () => {
  try {
    const { data, ok } = await api.get("/bonus/employeesList");
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const grantBonus = async (reqBody: GrantBonus) => {
  try {
    const { data, ok } = await api.post("/bonus/grant", reqBody);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
