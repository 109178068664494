import {
  CheckCircleIcon,
  FaceFrownIcon,
  FaceSmileIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import {
  IEvaluationTemplate,
  IPerformanceData,
  IPerformanceForm,
} from "../../types/interfaces/IPerformance.interface";
import { formatStarRating } from "../../utils/dateFromatter";
import StartRating from "./StarRatings";

interface Props {
  evalFormData?: IPerformanceForm;
  evalTemplateData?: IEvaluationTemplate;
  selectedEmployeeId: IPerformanceData;
  evalData: Function;
}

const PerFormanceRating: React.FC<Props> = ({
  evalFormData,
  evalTemplateData,
  selectedEmployeeId,
  evalData,
}) => {
  const points = evalTemplateData?.Points;
  const [selectedOptions, setSelectedOptions] = useState<{
    [key: string]: string;
  }>({});

  useEffect(() => {
    if (evalFormData?.EvaluationPoints) {
      const initialSelectedOptions = evalFormData.EvaluationPoints.reduce(
        (acc, point) => ({
          ...acc,
          [point.code]: point.feedBack,
        }),
        {}
      );
      setSelectedOptions(initialSelectedOptions);
    } else {
      // Define defaultOptions explicitly as an empty object
      const defaultOptions: { [key: string]: string } = {};
      if (points) {
        points.forEach((point) => {
          defaultOptions[point.Code] = "";
        });
      }
      setSelectedOptions(defaultOptions);
    }
  }, [evalFormData, points]);

  const handleOptionToggle = (code: string, feedback: string) => {
    setSelectedOptions((prevOptions) => {
      const newOptions = { ...prevOptions, [code]: feedback };
      const data = mapFeedback(newOptions);
      evalData(data);
      return newOptions;
    });
  };

  const mapFeedback = (input: { [key: string]: string }) => {
    const feedbackMap: any = {
      "As Expected": "As Expected",
      "Above Expectation": "Above Expectation",
      "Below Expectation": "Below Expectation",
      "Not Applicable": "Not Applicable",
    };

    return Object.entries(input).map(([code, feedback]) => {
      const paddedCode = code.padStart(2, "0");
      return {
        code: paddedCode,
        feedBack: feedbackMap[feedback] || feedback,
      };
    });
  };

  useEffect(() => {}, [evalFormData]);

  return (
    <>
      {evalFormData?.ID && (
        <>
          <div className="flex sm:flex-row flex-col sm:justify-between items-start sm:items-end gap-2">
            <div>
              <h2 className="text-[#333333] text-lg font-semibold">
                {evalFormData?.UserName}
              </h2>
              <p className="text-[10px] text-[#858585]">
                {evalFormData?.UserDesignation}
              </p>
            </div>
            <div>
              <p className="flex items-center gap-2 text-sm text-[#5C5C5C]">
                Rating
                <StartRating ratingValue={evalFormData?.StarRating || 0} />
                {formatStarRating(evalFormData?.Percentage)} %
              </p>
            </div>
          </div>
          <hr className="my-3" />
          <div className="Employees overflow-y-scroll h-[calc(100vh-500px)] lg:h-[calc(100vh-455px)]">
            {evalFormData?.EvaluationPoints?.map((data: any, index: any) => (
              <div className="flex items-start gap-2 py-2" key={index}>
                <p className="text-[#333333] text-sm font-semibold">
                  {data.code}
                </p>
                <div className="w-full">
                  <>
                    <p className="text-[#333333] text-xs mb-2">{data.point}</p>
                    <div className="flex lg:flex-row flex-col lg:items-center justify-between gap-3">
                      <div className="flex items-center border border-[#EBEBEB] rounded-lg max-w-fit">
                        <>
                          <button
                            className={`flex sm:flex-row flex-col items-center gap-1 rounded-l-lg text-[#962D2D] text-[10px] font-semibold px-1 sm:px-3 py-2 hover:bg-[#f5eaea] ${
                              selectedOptions[data.code] === "Below Expectation"
                                ? "bg-[#f5eaea]"
                                : ""
                            }`}
                            onClick={() =>
                              handleOptionToggle(data.code, "Below Expectation")
                            }
                          >
                            <FaceFrownIcon className="w-2.5 h-2.5" /> Below
                            Expectation
                          </button>
                          <button
                            className={`flex sm:flex-row flex-col items-center gap-1 text-[#378EB5] text-[10px] font-semibold px-1 sm:px-3 py-2 hover:bg-[#f0f7fb] ${
                              selectedOptions[data.code] === "As Expected"
                                ? "bg-[#f0f7fb]"
                                : ""
                            }`}
                            onClick={() =>
                              handleOptionToggle(data.code, "As Expected")
                            }
                          >
                            <CheckCircleIcon className="w-2.5 h-2.5" /> As
                            Expected
                          </button>
                          <button
                            className={`flex sm:flex-row flex-col items-center gap-1 rounded-r-lg text-[#23CDAF] text-[10px] font-semibold px-1 sm:px-3 py-2 hover:bg-[#e9faf7] ${
                              selectedOptions[data.code] === "Above Expectation"
                                ? "bg-[#e9faf7]"
                                : ""
                            }`}
                            onClick={() =>
                              handleOptionToggle(data.code, "Above Expectation")
                            }
                          >
                            <FaceSmileIcon className="w-2.5 h-2.5" /> Above
                            Expectation
                          </button>
                          <button
                            className={`flex sm:flex-row flex-col items-center gap-1 rounded-r-lg text-[#daa520] text-[10px] font-semibold px-1 sm:px-3 py-2 hover:bg-[#f5f5dc] ${
                              selectedOptions[data.code] === "Not Applicable"
                                ? "bg-[#f5f5dc]"
                                : ""
                            }`}
                            onClick={() =>
                              handleOptionToggle(data.code, "Not Applicable")
                            }
                          >
                            <FaceSmileIcon className="w-2.5 h-2.5" />
                            Not Applicable
                          </button>
                        </>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {!evalFormData?.ID && (
        <>
          <div className="Employees overflow-y-scroll h-[calc(100vh-300px)] lg:h-[calc(100vh-360px)]">
            {points &&
              points.map((point, index: number) => (
                <div className="flex items-start gap-2 py-2" key={index}>
                  <p className="text-[#333333] text-sm font-semibold">
                    {point.Code}
                  </p>
                  <div className="w-full">
                    <>
                      <p className="text-[#333333] text-xs mb-2">
                        {point.Point}
                      </p>
                      <div className="flex lg:flex-row flex-col lg:items-center justify-between gap-3">
                        <div className="flex items-center border border-[#EBEBEB] rounded-lg max-w-fit">
                          <>
                            <button
                              className={`flex sm:flex-row flex-col items-center gap-1 rounded-l-lg text-[#962D2D] text-[10px] font-semibold px-1 sm:px-3 py-2 hover:bg-[#f5eaea] ${
                                selectedOptions[point.Code] ===
                                "Below Expectation"
                                  ? "bg-[#f5eaea]"
                                  : ""
                              }`}
                              onClick={() =>
                                handleOptionToggle(
                                  point.Code,
                                  "Below Expectation"
                                )
                              }
                            >
                              <FaceFrownIcon className="w-2.5 h-2.5" /> Below
                              Expectation
                            </button>
                            <button
                              className={`flex sm:flex-row flex-col items-center gap-1 text-[#378EB5] text-[10px] font-semibold px-1 sm:px-3 py-2 hover:bg-[#f0f7fb] ${
                                selectedOptions[point.Code] === "As Expected"
                                  ? "bg-[#f0f7fb]"
                                  : ""
                              }`}
                              onClick={() =>
                                handleOptionToggle(point.Code, "As Expected")
                              }
                            >
                              <CheckCircleIcon className="w-2.5 h-2.5" /> As
                              Expected
                            </button>
                            <button
                              className={`flex sm:flex-row flex-col items-center gap-1 rounded-r-lg text-[#23CDAF] text-[10px] font-semibold px-1 sm:px-3 py-2 hover:bg-[#e9faf7] ${
                                selectedOptions[point.Code] ===
                                "Above Expectation"
                                  ? "bg-[#e9faf7]"
                                  : ""
                              }`}
                              onClick={() =>
                                handleOptionToggle(
                                  point.Code,
                                  "Above Expectation"
                                )
                              }
                            >
                              <FaceSmileIcon className="w-2.5 h-2.5" /> Above
                              Expectation
                            </button>
                            <button
                              className={`flex sm:flex-row flex-col items-center gap-1 rounded-r-lg text-[#daa520] text-[10px] font-semibold px-1 sm:px-3 py-2 hover:bg-[#f5f5dc] ${
                                selectedOptions[point.Code] === "Not Applicable"
                                  ? "bg-[#f5f5dc]"
                                  : ""
                              }`}
                              onClick={() =>
                                handleOptionToggle(point.Code, "Not Applicable")
                              }
                            >
                              <FaceSmileIcon className="w-2.5 h-2.5" />
                              Not Applicable
                            </button>
                          </>
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
    </>
  );
};

export default PerFormanceRating;
