import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DeletePerformance from "../Modals/DeletePerformance";
import StartRating from "./StarRatings";
interface MonthPerformanceCardProps {
  UserId: String;
  Rating: number;
  isPercentage: boolean | any;
  createdAt: any;
  monthName: string;
  percentage: number;
  monthId: string;
  deleteModal: Function;
  userName: string;
  year: number;
  setHistory: Function;
  history: any;
}

const MonthPerformanceCard: React.FC<MonthPerformanceCardProps> = ({
  UserId,
  Rating,
  isPercentage,
  createdAt,
  monthName,
  percentage,
  monthId,
  deleteModal,
  userName,
  year,
  setHistory,
  history,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [edit, setEdit] = useState<boolean>(false);
  const navigate = useNavigate();
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleEditPressed = (data: any) => {
    setEdit(true);
    setHistory(false, data);
  };
  return (
    <>
      <div className="border border-[#D6D6D6] rounded-lg p-2">
        <h3 className="text-base font-semibold text-[#333333] mb-3">
          {monthName}
        </h3>
        <p className="flex items-center gap-2 mb-3 text-sm text-[#5C5C5C] font-semibold">
          <StartRating ratingValue={Rating} /> {percentage}%
        </p>
        <div className="flex items-center justify-between">
          <p>{createdAt}</p>
          <div className="flex items-center">
            <button
              onClick={() => handleEditPressed(history)}
              className="text-[#378EB5] p-2"
            >
              <PencilSquareIcon className="w-3 h-3" />
            </button>
            <button onClick={openModal} className="text-[#962D2D] p-2">
              <TrashIcon className="w-3 h-3" />
            </button>
            <DeletePerformance
              isOpen={isModalOpen}
              onClose={closeModal}
              monthId={monthId}
              deleteModal={() => deleteModal()}
              monthName={monthName}
              year={year}
              userName={userName}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MonthPerformanceCard;
