import { DocumentTextIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import EmployeeStatus from "../../components/Employeestatus/EmployeeStatus";
import PaySlipsTable from "../../components/Table/PaySlipsTable";
import { fetchAllEmpPaySlipsData } from "../../services/paySlips.service";
import { Payroll } from "../../types/interfaces/IPaySlipData";

export interface LatestSlipDate {
  month: string;
  year: string;
}

const PaySlips = () => {
  const [latestSlipMonth, setLatestSlipMonth] = useState<LatestSlipDate>();
  const [date, setDate] = useState<string>("");
  const [empData, setEmpData] = useState<Payroll[]>([]);

  const getEmp = async (month?: string, year?: string, search?: string) => {
    try {
      const res: any = await fetchAllEmpPaySlipsData(month, year, search);
      if (res?.res) {
        const month = res.res?.latestPayrollAvailable?.month || "";
        const year = res.res?.latestPayrollAvailable?.year || "";
        if (month && year) {
          setDate(`${month} ${year}`);
        } else {
          setDate("N/A");
        }

        setEmpData(res.res?.payroll as Payroll[]);
      }
      if (res.err) {
        setEmpData([] as Payroll[]);
      }
    } catch (error) {
      console.error("Error fetching employee payroll data:", error);
    }
  };

  useEffect(() => {
    getEmp();
  }, []);

  return (
    <>
      <div className="basis-full px-[15px] xl:px-[30px]">
        <EmployeeStatus
          heading="Payroll"
          leadText="Manage pay slips of employees"
          StatusLogo={<DocumentTextIcon className="w-9 h-9 text-[#33475F]" />}
          Subscription="Latest Payroll Available"
          price={date}
          showFirst={true}
          showSecond={false}
        />
        <div className="basis-2/3">
          <PaySlipsTable
            setLatestSlipMonth={setLatestSlipMonth}
            empData={empData}
            getEmp={getEmp}
            calendarDate={date}
          />
        </div>
      </div>
    </>
  );
};

export default PaySlips;
