import { XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";

interface Props {
  comments: any[];
  onClose: () => void;
}

const CommentsPopOver: React.FC<Props> = ({ comments, onClose }) => {
  const stopPropagation = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
  };

  return (
    <>
      {comments.length > 0 && (
        <div
          className="absolute top-1/2 transform translate-y-[8%] translate-x-[-88%] z-10 bg-white border border-gray-300 shadow-3xl rounded-md w-[250px] sm:w-[300px] lg:w-[350px]"
          onClick={stopPropagation}
        >
          <div className="flex justify-between items-center px-4 py-4">
            <h3 className="text-base font-semibold text-[#171717]">Comments</h3>
            <button onClick={onClose}>
              <XMarkIcon className="w-6 h-6" />
            </button>
          </div>
          <hr />
          <ul className="overflow-y-scroll h-36 px-4">
            {comments.map((comment, index) => (
              <li
                key={index}
                className="text-justify py-2 hover:bg-[#DDECF4] active:bg-[#DDECF4] text-[#33475F] text-base font-semibold rounded-lg"
              >
                <span className="text-sm text-[#333333] font-bold">
                  {comment.Name}:
                </span>
                <p className="text-sm text-[#5C5C5C] inline">{` ${comment.Comment}`}</p>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default CommentsPopOver;
