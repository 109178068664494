import {
  ExclamationCircleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import {
  IEmployeesManagement,
  User,
} from "../../types/interfaces/IEmployeesManagement.interface";

interface Props {
  employees?: IEmployeesManagement;
  selectedEmployee?: User;
  setSelectedEmployee?: (user: User) => void;
}

const TotalEmployees: React.FC<Props> = ({
  employees,
  selectedEmployee,
  setSelectedEmployee,
}) => {
  const [filteredEmps, setFilteredEmps] = useState<User[]>([]);
  const [searchEmp, setSearchEmp] = useState("");

  const handleSearchEmp = () => {
    const filteredEmps = employees?.users?.filter((user) =>
      user?.Name?.toLowerCase().includes(searchEmp.toLowerCase())
    );
    setFilteredEmps(filteredEmps as User[]);
  };

  useEffect(() => {
    if (employees?.users && employees?.users?.length > 0) {
      setFilteredEmps(employees?.users);
    }
  }, [employees]);
  console.log("filtered emp: ", filteredEmps);
  useEffect(() => {
    handleSearchEmp();
  }, [searchEmp]);
  return (
    <>
      <div>
        <h2 className="text-xl text-[#333333] mb-4 font-semibold">Employees</h2>
        <div className="relative flex items-center mb-4">
          <input
            className="cursor-text w-full py-[10px] ps-10 pe-3 border border-[#D6D6D6] bg-[#ffffff] rounded-lg focus:outline-none"
            type="search"
            placeholder="Search"
            value={searchEmp}
            onChange={(event) => setSearchEmp(event?.target?.value)}
          />
          <span className="absolute top-3 left-3">
            <MagnifyingGlassIcon className="w-5 h-5 text-[#858585]" />
          </span>
        </div>
        <div className="capitalize Employees border-[#EBEBEB] border rounded-2xl p-3 bg-white overflow-y-scroll md:h-[calc(100vh-375px)] h-[calc(100vh-615px)]">
          {filteredEmps?.map((user, index) => (
            <div
              className={`flex items-center justify-between hover:bg-[#F0F7FB] rounded-md py-4 px-3 cursor-pointer ${
                selectedEmployee?.ID === user?.ID && "bg-[#F0F7FB]"
              }`}
              onClick={() => setSelectedEmployee && setSelectedEmployee(user)}
            >
              <div className="flex items-center gap-3">
                <p className="text-sm text-[#5C5C5C] w-6">{index + 1}</p>
                <div>
                  <p className="text-sm text-[#33475F] font-semibold capitalize">
                    {user?.Name}
                  </p>
                  <p className="text-[10px] text-[#858585] font-semibold">
                    {user?.Designation}
                  </p>
                </div>
              </div>

              {!user?.IsInfoCompleted && (
                <span>
                  <ExclamationCircleIcon className="text-[#962D2D] w-5 h-5" />
                </span>
              )}
              {!user?.Active && (
                <span className="bg-[#333333] text-white font-semibold text-[10px] rounded-[4px] px-2 py-0.5 uppercase">
                  Deactivated
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default TotalEmployees;
