import { BuildingOffice2Icon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import EmployeeStatus from "../../components/Employeestatus/EmployeeStatus";
import MedicalAllowancTable from "../../components/medicalAllownceTable/MedicalAllowanceTable";
import {
  fetchMedicalAllowanceInfo,
  fetchMedicalRequests,
} from "../../services/medicalAllowance.service";
import {
  IMedicalAllowance,
  IMedicalCountInfo,
} from "../../types/interfaces/IMedicalAllowance.interface";

const MedicalAllowance = () => {
  const [medicalCountInfo, setMedicalCountInfo] = useState<IMedicalCountInfo>();
  const [openPopoverId, setOpenPopoverId] = useState<string | null>(null);
  const [medicalAllowanceRequests, setMedicalAllowanceRequest] =
    useState<IMedicalAllowance>({ medicalRequests: [], pendingCount: 0 });
  const getMedicalAllowanceInfo = async () => {
    const res = await fetchMedicalAllowanceInfo();
    if (res?.res) {
      setMedicalCountInfo(res?.res as IMedicalCountInfo);
    }
  };

  const getMedicalRequests = async (
    startDate?: string,
    endDate?: string,
    searchQuery?: string
  ) => {
    const res = await fetchMedicalRequests(startDate, endDate, searchQuery);
    if (res?.res) {
      setMedicalAllowanceRequest(res?.res as IMedicalAllowance);
    }
  };
  useEffect(() => {
    getMedicalAllowanceInfo();
  }, []);

  return (
    <div
      className="basis-full px-[15px]"
      onClick={() => {
        openPopoverId && openPopoverId?.length > 0 && setOpenPopoverId(null);
      }}
    >
      <div className="">
        <EmployeeStatus
          heading="Medical Allowance"
          leadText="Manage medical allowance of employees"
          StatusLogo={
            <BuildingOffice2Icon className="w-9 h-9 text-[#33475F]" />
          }
          Subscription="Pending Medical"
          Month={medicalCountInfo?.pendingMedicals?.oldestMonth}
          price={
            medicalAllowanceRequests.pendingCount
              ? medicalAllowanceRequests.pendingCount
              : "0"
          }
          showFirst={true}
          showSecond={false}
        />
        <div className="basis-2/3">
          <MedicalAllowancTable
            getMedicalAllowanceInfo={getMedicalAllowanceInfo}
            openPopoverId={openPopoverId}
            setOpenPopoverId={setOpenPopoverId}
            getMedicalRequests={getMedicalRequests}
            medicalAllowanceRequests={medicalAllowanceRequests}
          />
        </div>
      </div>
    </div>
  );
};

export default MedicalAllowance;
