import { api } from "../utils/axios";

export const fetchAllEncashments = async (
  startDate?: string,
  endDate?: string,
  search?: string
) => {
  try {
    const { data, ok } = await api.get(
      `/encashment/getAllEncashments?${search ? "search=" + search : ""}${
        startDate ? "&startDate=" + startDate + "&endDate=" + endDate : ""
      }`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const updateEncashmentStatus = async (
  status: string,
  uncashmentId: string,
  reqBody: { comment: string }
) => {
  try {
    const { data, ok } = await api.patch(
      `/encashment/respondEncashment/${status}/${uncashmentId}`,
      reqBody
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const updateEncashmentPaymentStatus = async (encashmentId: string) => {
  try {
    const { data, ok } = await api.patch(
      `/encashment/markEncashmentAsPaid/${encashmentId}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
