import React, { useEffect, useState } from "react";
import MenuModal from "../custom-modal/MenuModal";

import { XMarkIcon } from "@heroicons/react/24/outline";
import { CalendarDaysIcon } from "@heroicons/react/24/solid";
import { User } from "../../types/interfaces/IEmployeesManagement.interface";
import { formatDateSlash } from "../../utils/dateFromatter";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface WorkHistoryProps {
  isAddModal: boolean;
  isCloseModal: () => void;
  addPerk: Function;
  selectedEmployee: User;
}

interface Employee {
  userId: string;
  name: string;
}

const AddPerkHistory: React.FC<WorkHistoryProps> = ({
  isAddModal,
  isCloseModal,
  addPerk,
  selectedEmployee,
}) => {
  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [amountError, setAmountError] = useState<string>("");
  const today = new Date();
  const [dateRange, setDateRange] = useState<Date[]>([today, today]);
  const [since, till] = dateRange;

  const handleClear = () => {
    setSelectedDate(null);
  };

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    isCloseModal();
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!amount || amount <= 0) {
      setAmountError("Please enter a valid amount");
      return;
    }
    await addPerk({
      amount: amount,
      since: formatDateSlash(since),
      till: formatDateSlash(till),
      userId: selectedEmployee?.ID,
      designation: selectedEmployee?.Designation,
    });
    isCloseModal();
  };
  const handleKeyDown = (e: any) => {
    e.preventDefault();
  };

  const currency = localStorage.getItem("currency") || "USD";
  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const parseCurrency = (value: string) => {
    return parseInt(value.replace(/[^0-9]+/g, ""), 10);
  };

  useEffect(() => {
    if (isAddModal) {
      setAmount(undefined);
      setDateRange([today, today]);
      setAmountError("");
    }
  }, [isAddModal]);

  return (
    <MenuModal openModal={isAddModal} closeModal={isCloseModal}>
      <div className="flex flex-col">
        <div className="border-b border p-4">
          <h2 className="font-semibold text-xl">Perk History</h2>
        </div>
        <div className="p-4">
          <form className="flex flex-col gap-4">
            <div className="flex gap-4">
              <div className="flex flex-col gap-2 w-full">
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Amount
                  </label>
                  <input
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                    type="text"
                    placeholder="Amount"
                    value={amount !== undefined ? formatCurrency(amount) : ""}
                    onChange={(event) => {
                      const parsedAmount = parseCurrency(event.target.value);
                      setAmount(
                        !isNaN(parsedAmount) ? parsedAmount : undefined
                      );
                    }}
                  />
                  {amountError && (
                    <p className="text-red-500 text-sm">{amountError}</p>
                  )}
                </div>
                <div className="mb-4 relative datePerk">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Month and Year
                  </label>
                  <ReactDatePicker
                    className="cursor-pointer rounded-lg w-full bg-[#ffffff] border border-[#D6D6D6] text-sm py-3 px-5 placeholder:text-[#333] placeholder:font-medium"
                    onChange={(date) => {
                      setDateRange(date as Date[]);
                    }}
                    startDate={since}
                    endDate={till}
                    placeholderText="Select Range"
                    selectsRange={true}
                    dateFormat="dd/MM/yyyy"
                    preventOpenOnFocus
                    onKeyDown={(e) => handleKeyDown(e)}
                  />
                  {selectedDate ? (
                    <button
                      className="absolute top-9 right-4"
                      onClick={handleClear}
                    >
                      <XMarkIcon className="text-black w-5 h-5" />
                    </button>
                  ) : (
                    <span className="absolute top-9 right-4 pointer-events-none">
                      <CalendarDaysIcon className="w-5 h-5 text-[#333]" />
                    </span>
                  )}
                </div>
                <div className="flex justify-end items-center gap-2">
                  <button
                    onClick={handleClose}
                    className="text-sm font-semibold text-[#333333] py-2.5 px-3 rounded-lg border-[#D4D4D4] border w-[72px]"
                  >
                    Cancel
                  </button>
                  <button
                    className="text-sm font-semibold text-white bg-[#378EB5] py-2.5 px-3 rounded-lg w-[76px] h-10"
                    onClick={handleSubmit}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </MenuModal>
  );
};

export default AddPerkHistory;
