import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";

interface Props {
  suggestion: string;
  onClose: () => void;
}

const SuggestionPopOver: React.FC<Props> = ({ suggestion, onClose }) => {
  const [positionAbove, setPositionAbove] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, onClose);

  const stopPropagation = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
  };

  useEffect(() => {
    const adjustPosition = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        setPositionAbove(rect.bottom > viewportHeight);
      }
    };

    adjustPosition();
    window.addEventListener("resize", adjustPosition);
    window.addEventListener("scroll", adjustPosition);

    return () => {
      window.removeEventListener("resize", adjustPosition);
      window.removeEventListener("scroll", adjustPosition);
    };
  }, []);

  return (
    <div
      ref={ref}
      onClick={stopPropagation}
      className={`absolute z-10 bg-white border border-gray-300 shadow-3xl rounded-md w-[400px] sm:w-[300px] lg:w-[400px] ${
        positionAbove
          ? "top-auto bottom-full mb-2"
          : "top-1/2 transform translate-y-[8%] translate-x-[-88%]"
      }`}
    >
      <div className="flex justify-between items-center px-4 py-3">
        <h3 className="text-base font-semibold text-[#171717]">Suggestion</h3>
        <button onClick={onClose}>
          <XMarkIcon className="w-6 h-6" />
        </button>
      </div>
      <hr />
      <ul className="overflow-y-scroll h-24 px-4">
        <p className="text-sm text-[#5C5C5C]">{suggestion}</p>
      </ul>
    </div>
  );
};

export default SuggestionPopOver;
