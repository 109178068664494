import { useState } from "react";
import EmployeeStatus from "../../components/Employeestatus/EmployeeStatus";

import { CurrencyDollarIcon } from "@heroicons/react/24/solid";
import BonusTable from "../../components/Table/BonusTable";
import { fetchAllBonus } from "../../services/bonus.service";
import { allBonuses } from "../../types/interfaces/IBonus";
import { ISalariesCountInfo } from "../../types/interfaces/ISalaryRecords.interface";

const BonusScreen = () => {
  const [openPopoverId, setOpenPopoverId] = useState<string | null>(null);
  const [bonus, setBonus] = useState<allBonuses>();
  const [totalBonus, setTotalBonus] = useState(0);

  const getAllBonus = async (year?: string) => {
    const res: any = await fetchAllBonus(year);
    if (res?.res) {
      setBonus(res?.res as allBonuses);
      setTotalBonus(res.res.allBonuses.length);
    }
  };

  return (
    <div
      onClick={() => {
        openPopoverId && openPopoverId?.length > 0 && setOpenPopoverId(null);
      }}
      className="basis-full px-[15px] xl:px-[30px]"
    >
      <div className="">
        <EmployeeStatus
          heading="Bonus"
          leadText="See all Bonus"
          StatusLogo={<CurrencyDollarIcon className="w-9 h-9 text-[#33475F]" />}
          Subscription=""
          showFirst={false}
          showSecond={true}
          TotalSubscriptions={"Total Bonuses"}
          Total={totalBonus}
          Request={``}
          PendingDate={""}
        />
        <div className="basis-2/3">
          <BonusTable
            openPopoverId={openPopoverId}
            setOpenPopoverId={setOpenPopoverId}
            advSalaryRequests={bonus}
            setAdvSalaryRequests={setBonus}
            getAllAdvSalary={getAllBonus}
          />
        </div>
      </div>
    </div>
  );
};

export default BonusScreen;
