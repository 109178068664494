import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { CalendarDaysIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import {
  fetchAllSubs,
  subscribeToLunch,
  unSubscribeToLunch,
} from "../../services/lunchManagement.service";
import { ILunchSubs } from "../../types/interfaces/ILunchSubs.interface";
import { formatDateSlash, formatDateSpace } from "../../utils/dateFromatter";

interface Props {
  getLunchSubsInfo: () => void;
}

const LunchSubscriptionTable: React.FC<Props> = ({ getLunchSubsInfo }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const [lunchSubsRequests, setLunchSubsRequests] = useState<ILunchSubs>({
    records: [],
  });
  const [reqAgain, setReqAgain] = useState(false);
  const [dateRange, setDateRange] = useState<Date[]>([]);
  const [startDate, endDate] = dateRange?.length > 0 ? dateRange : [];
  const [subscribe, setSubscribe] = useState<any>("");

  let timeoutId: NodeJS.Timeout | null = null;

  const getAllSubs = async (
    startDate?: string,
    endDate?: string,
    searchQuery?: string
  ) => {
    const res = await fetchAllSubs(startDate, endDate, searchQuery);
    if (res?.res) {
      setLunchSubsRequests(res?.res as ILunchSubs);
    }
  };

  useEffect(() => {
    if (!dateRange[1]) return;
    setReqAgain(!reqAgain);
  }, [dateRange]);

  useEffect(() => {
    console.log("FFFFFFFFFFFFFFFFFFF");

    if (searchQuery) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        if (dateRange?.length > 0) {
          getAllSubs(
            formatDateSlash(startDate),
            formatDateSlash(endDate),
            searchQuery
          );
        } else getAllSubs(undefined, undefined, searchQuery);
      }, 1000);
    } else if (dateRange?.length > 0) {
      getAllSubs(formatDateSlash(startDate), formatDateSlash(endDate));
    } else {
      getAllSubs();
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [reqAgain, searchQuery, subscribe]);

  const handleClear = () => {
    setDateRange([]);
    setReqAgain(!reqAgain);
  };

  const handleUnsubscribe = async (subId: string) => {
    const resp = await unSubscribeToLunch(subId);
    if (resp?.res) {
      setSubscribe(resp);
      console.log(resp);
      toast.success("Unsbscribed Successfully");
    }
  };

  const handleKeyDown = (e: any) => {
    e.preventDefault();
  };

  return (
    <>
      <div className="py-5 ">
        <div className="relative flex md:flex-row flex-col md:items-center  mb-3 md:mb-6 gap-3">
          <input
            className="cursor-text w-full md:max-w-[310px] py-[10px] ps-10 pe-4  border border-[#D6D6D6] bg-[#ffffff] rounded-lg focus:outline-none placeholder:text-sm"
            type="search"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e?.target?.value)}
          />
          <span className="absolute top-3 left-3">
            <MagnifyingGlassIcon className="w-5 h-5 text-[#858585]" />
          </span>
          <div className="relative mb-3 md:mb-0 flex items-center z-10">
            <ReactDatePicker
              className="cursor-pointer rounded-lg min-w-[230px] w-full bg-[#ffffff] border border-[#D6D6D6] text-sm py-3 px-5 placeholder:text-[#333] placeholder:font-medium"
              onChange={(date) => {
                setDateRange(date as Date[]);
              }}
              startDate={startDate}
              endDate={endDate}
              placeholderText="Select Range"
              selectsRange={true}
              dateFormat="dd/MM/yyyy"
              preventOpenOnFocus
              onKeyDown={(e) => handleKeyDown(e)}
            />
            {endDate ? (
              <button className="absolute top-3 right-4" onClick={handleClear}>
                <XMarkIcon className="text-black w-5 h-5" />
              </button>
            ) : (
              <span className="absolute  top-3 right-4 pointer-events-none">
                <CalendarDaysIcon className="w-5 h-5 text-[#333]" />
              </span>
            )}
          </div>
        </div>
        {lunchSubsRequests?.records?.length > 0 ? (
          <>
            <div className="Employees md:px-0 overflow-scroll overflow-x-hidden h-[calc(100vh-325px)]">
              <table className="capitalize w-full">
                <thead className="sticky top-0">
                  <tr>
                    <th></th>
                    <th>Employee Name</th>
                    <th>Subscribed Date</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {lunchSubsRequests?.records?.map((sub, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td data-cell="Employee Name">{sub?.name}</td>
                      <td data-cell="Subscribed Date">
                        {formatDateSpace(
                          moment.utc(sub?.subscribedDate).format("DD/MM/YYYY")
                        )}
                      </td>
                      <td data-cell="Status">
                        <span
                          className={`rounded-[4px] py-1 px-2 text-[10px] font-semibold text-[#33475F] bg-[#DDECF4]`}
                        >
                          {sub.subscriptionStatus
                            ? "SUBSCRIBED"
                            : "UNSUBSCRIBED"}
                        </span>
                      </td>
                      <td>
                        {sub.subscriptionStatus ? (
                          <button
                            className={` text-[10px] w-[83px] btnReject`}
                            onClick={() => handleUnsubscribe(sub.userId)}
                          >
                            {"Unsubscribe"}
                          </button>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div className="text-center flex flex-col justify-center mt-16">
            <span className="text-center flex justify-center mb-3">
              <img src="/images/no-data.png" alt="no have data" />{" "}
            </span>
            <p className="text-sm font-normal text-[#5C5C5C]">
              No Lunch Subscriptions Available.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default LunchSubscriptionTable;
