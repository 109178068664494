import { CalendarDaysIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { endOfMonth } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { toast } from "react-toastify";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { fetchEmployees } from "../../services/bonus.service"; // Assuming you have a service to fetch employees

interface Employee {
  userId: string;
  name: string;
}

interface Props {
  onClose: () => void;
  onSubmit: (data: any) => void; // Replace `any` with your data type if available
}

const BonusForm: React.FC<Props> = ({ onClose, onSubmit }) => {
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const [bonusAmount, setBonusAmount] = useState<number | undefined>(undefined);
  const [purpose, setPurpose] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [amountError, setAmountError] = useState<string | null>(null);

  const ref: any = useRef();
  function handleClose() {
    setBonusAmount(undefined);
    setPurpose("");
    onClose();
  }
  useOnClickOutside(ref, handleClose);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response: any = await fetchEmployees();
        setEmployees(response?.res.employeesList);
      } catch (error) {
        toast.error("Failed to fetch employees");
      }
    };

    fetchEmployeeData();
  }, []);

  const handleEmployeeChange = (selectedOption: any) => {
    setSelectedEmployee(selectedOption);
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value ? +e.target.value : undefined;
    setBonusAmount(value);

    if (value !== undefined && value < 0) {
      setAmountError("Bonus amount cannot be negative");
    } else {
      setAmountError(null);
    }
  };

  const handleSubmit = () => {
    if (!selectedEmployee || !bonusAmount || !purpose || !selectedDate) {
      toast.error("Please fill all fields");
      return;
    }

    if (bonusAmount < 0) {
      toast.error("Bonus amount cannot be negative");
      return;
    }

    const data = {
      userId: selectedEmployee.value,
      amount: bonusAmount,
      purpose: purpose,
      month: selectedDate.getMonth() + 1,
      year: selectedDate.getFullYear(),
    };
    onSubmit(data);
  };

  const handleClear = () => {
    setSelectedDate(null);
  };

  const currency = localStorage.getItem("currency") || "USD"; // Default to USD if not found

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const parseCurrency = (value: string) => {
    return parseInt(value.replace(/[^0-9]+/g, ""), 10);
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 z-20 flex items-center justify-center">
      <div
        ref={ref}
        className="bg-white rounded-lg shadow-md w-[600px] p-6 relative"
      >
        <div className="flex justify-between items-center py-2">
          <h3 className="text-base font-semibold text-[#171717]">Add Bonus</h3>
          <button onClick={handleClose}>
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>
        <div className="cursor-pointer mb-4 my-3">
          <label className="block text-sm font-medium text-gray-700">
            Employee
          </label>
          <Select
            className="cursor-pointer my-1"
            options={employees.map((emp) => ({
              value: emp.userId,
              label: emp.name,
            }))}
            onChange={handleEmployeeChange}
            value={selectedEmployee}
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Bonus Amount
          </label>
          <input
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            type="text"
            placeholder="Amount"
            value={bonusAmount !== undefined ? formatCurrency(bonusAmount) : ""}
            onChange={(event) => {
              const parsedAmount = parseCurrency(event.target.value);
              setBonusAmount(!isNaN(parsedAmount) ? parsedAmount : undefined);
            }}
          />
          {amountError && <p className="text-red-500 text-sm">{amountError}</p>}
        </div>
        <div className="mb-4 relative">
          <label className="block text-sm font-medium text-gray-700">
            Month and Year
          </label>
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            className="cursor-pointer mt-1 block w-full p-2 border border-gray-300 rounded-md"
            maxDate={endOfMonth(new Date())}
          />
          {selectedDate ? (
            <button className="absolute top-9 right-4" onClick={handleClear}>
              <XMarkIcon className="text-black w-5 h-5" />
            </button>
          ) : (
            <span className="absolute top-9 right-4 pointer-events-none">
              <CalendarDaysIcon className="w-5 h-5 text-[#333]" />
            </span>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Purpose
          </label>
          <select
            className="cursor-pointer mt-1 block w-full p-2 border border-gray-300 rounded-md"
            value={purpose}
            onChange={(e) => setPurpose(e.target.value)}
          >
            <option value="" disabled>
              Select Purpose
            </option>
            <option value="Project based">Project based</option>
            <option value="Annual bonus">Annual bonus</option>
          </select>
        </div>
        <div className="flex justify-end items-center gap-2">
          <button
            onClick={handleClose}
            className="text-sm font-semibold text-[#333333] py-2.5 px-3 rounded-lg border-[#D4D4D4] border w-[72px]"
          >
            Cancel
          </button>
          <button
            className="text-sm font-semibold text-white bg-[#378EB5] py-2.5 px-3 rounded-lg w-[76px] h-10"
            onClick={handleSubmit}
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default BonusForm;
