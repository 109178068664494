import { Configuration } from "@azure/msal-browser";

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID || "",
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_AD_TENANT_ID}`,
    redirectUri: `${window.location.origin}`,
    postLogoutRedirectUri: `${window.location.origin}/sign-in`,
  },
  system: {
    allowNativeBroker: false,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: [process.env.REACT_APP_AZURE_AD_SCOPE || ""],
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

export const protectedResources = {
  apiService: {
    endpoint: process.env.REACT_APP_API_URL,
    scopes: [process.env.REACT_APP_AZURE_AD_SCOPE || ""], // Make sure this is an array
  },
};
