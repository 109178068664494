import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  CalendarDaysIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import moment from "moment";
import React, { useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import {
  INoticeBoard,
  NoticeDetails,
} from "../../types/interfaces/INoticeBoard.interface";
import { formatDateSpace } from "../../utils/dateFromatter";

interface Props {
  allNotices: INoticeBoard;
  handleNoticeAction: (notice: NoticeDetails, action: string) => void;
  setDateRange: (date: Date[]) => void;
  startDate: Date;
  endDate: Date;
  searchQuery: string;
  setSearchQuery: (search: string) => void;
  handleClear: () => void;
  scrollToTopRef: React.RefObject<HTMLDivElement>; // Add this prop
}

const AllNotices: React.FC<Props> = ({
  allNotices,
  handleNoticeAction,
  setDateRange,
  startDate,
  endDate,
  searchQuery,
  setSearchQuery,
  handleClear,
  scrollToTopRef, // Use this prop
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [noticeToDelete, setNoticeToDelete] = useState<NoticeDetails | null>(
    null
  );

  const confirmDelete = (notice: NoticeDetails) => {
    setNoticeToDelete(notice);
    setShowDeleteModal(true);
  };

  const handleDelete = () => {
    if (noticeToDelete) {
      handleNoticeAction(noticeToDelete, "delete");
      setShowDeleteModal(false);
      setNoticeToDelete(null);
    }
  };

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => setShowDeleteModal(false));

  const handleKeyDown = (e: any) => {
    e.preventDefault(); // Prevent all key presses
  };

  return (
    <>
      <div className="bg-white border border-[#EBEBEB] rounded-2xl p-2 lg:px-4 lg:py-5">
        <h3 className="text-lg text-[#33475F] font-semibold mb-4">
          All Announcements
        </h3>
        <>
          <div className="relative flex flex-col gap-2">
            <input
              className="w-full py-[10px] ps-10 pe-4 border border-[#D6D6D6] bg-[#ffffff] rounded-lg focus:outline-none"
              type="search"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e?.target?.value)}
            />
            <span className="absolute top-3 left-3">
              <MagnifyingGlassIcon className="w-5 h-5 text-[#858585]" />
            </span>
            <div className="relative mb-3">
              <ReactDatePicker
                className="cursor-pointer rounded-lg w-full lg:min-w-[150px] bg-[#ffffff] border border-[#D6D6D6] text-sm py-3 px-5 placeholder:text-[#333] placeholder:font-medium"
                onChange={(date) => {
                  setDateRange(date as Date[]);
                }}
                startDate={startDate}
                endDate={endDate}
                placeholderText="Select Range"
                selectsRange={true}
                dateFormat="dd/MM/yyyy"
                preventOpenOnFocus
                onKeyDown={(e) => handleKeyDown(e)}
              />

              {endDate ? (
                <button
                  className="absolute top-3 right-4"
                  onClick={() => handleClear()}
                >
                  <XMarkIcon className="text-black w-5 h-5" />
                </button>
              ) : (
                <span className="absolute top-3 right-4 pointer-events-none">
                  <CalendarDaysIcon className="w-5 h-5 text-[#333]" />
                </span>
              )}
            </div>
          </div>
          {allNotices?.notices?.length > 0 ? (
            <div
              className="Employees  overflow-hidden overflow-y-auto overflow-x-hidden h-[calc(100vh-630px)] lg:h-[calc(100vh-535px)]"
              ref={scrollToTopRef} // Attach the ref to the scrollable container
            >
              {allNotices.notices.map((notice, index) => (
                <div
                  key={index}
                  className="flex flex-col  border rounded-lg p-3 gap-2 my-3 mx-2 max-h-[210px]"
                >
                  <h3 className=" font-bold text-wrap text-sm mb-2">
                    {notice?.Title}
                  </h3>
                  <div className="Employees flex items-start overflow-y-auto overflow-x-hidden max-h-24">
                    <p className="break-words">
                      {notice?.Description.split("\n").map((line, idx) => (
                        <p className="text-justify" key={idx}>
                          {line}
                          <br />
                        </p>
                      ))}
                    </p>
                  </div>
                  <div className="flex justify-between">
                    <div className="flex">
                      <button
                        className="px-[6px] py-[6px]"
                        onClick={() => handleNoticeAction(notice, "edit")}
                      >
                        <PencilIcon className="w-3 h-3 text-primary text-blue-800" />
                      </button>
                      <button
                        className="px-[6px] py-[6px]"
                        onClick={() => confirmDelete(notice)}
                      >
                        <TrashIcon className="w-3 h-3 text-primary text-blue-800" />
                      </button>
                    </div>
                    <p>
                      {formatDateSpace(
                        moment(notice?.CreatedAt).format("DD/MM/YYYY")
                      )}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center flex flex-col justify-center py-16">
              <span className="text-center flex justify-center mb-3">
                <img src="/images/no-notices.png" alt="No notices available" />
              </span>
              <p className="text-lg font-normal text-[#333333]">
                No result found
              </p>
            </div>
          )}
        </>
      </div>

      {showDeleteModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-5" ref={ref}>
            <h2 className="text-lg font-semibold mb-4">Confirm Delete</h2>
            <p className="mb-4">Are you sure you want to delete this notice?</p>
            <div className="flex justify-end gap-2">
              <button
                className="bg-gray-300 text-gray-700 rounded px-4 py-2"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-[#378EB5] text-white rounded px-4 py-2"
                onClick={handleDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AllNotices;
