import {
  MagnifyingGlassIcon,
  PhotoIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { CalendarDaysIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import {
  updateSchoolingPayment,
  updateSchoolingStatus,
} from "../../services/schooling.service";
import { IFuel } from "../../types/interfaces/IFuelAllowance.interface";
import { ISchool } from "../../types/interfaces/ISchooling";
import { formatAmount, formatDateSpace } from "../../utils/dateFromatter";
import { scrollToTop } from "../../utils/tableScroller";
import ApproveRejectModal from "../Modals/ApproveRejectModal";
import ViewAttachments from "../Modals/ViewAttachments";

interface Props {
  schooling: any;
  schoolingFunction: Function;
}
const FuelAllowanceTable: React.FC<Props> = ({
  schooling,
  schoolingFunction,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [fuelRequests, setFuelRequests] = useState<IFuel>();
  const [reqAgain, setReqAgain] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [scrollOnDate, setScrollOnDate] = useState(false);
  let timeoutId: NodeJS.Timeout | null = null;
  let scrollToTopRef = useRef<HTMLTableSectionElement>(null);
  const [showPayModal, setShowPayModal] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comments, setComments] = useState("");
  const [actionToPerform, setActionToPerform] = useState("");
  const [paymentStatus, setPaymentStatus] = useState<any>("");

  const [monthYear, setMonthYear] = useState<{
    month: number | "";
    year: number | "";
  }>();

  const ref: any = useRef();
  useOnClickOutside(ref, () => setShowPayModal(false));

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
    const month = moment(date).month() + 1;
    const year = moment(date).year();
    setMonthYear({ month, year });
    console.log("month year", monthYear);
    schoolingFunction(month.toString(), year.toString());
  };
  useEffect(() => {
    if (!selectedDate) return;
    setReqAgain(!reqAgain);
    setScrollOnDate(!scrollOnDate);
  }, [selectedDate]);

  const handleClear = () => {
    setSelectedDate(undefined);
    setReqAgain(!reqAgain);
    setMonthYear({ month: "", year: "" });
    schoolingFunction();
  };

  useEffect(() => {
    scrollToTop(scrollToTopRef);
  }, [searchQuery, scrollOnDate, monthYear, paymentStatus]);

  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<ISchool>();

  const openViewModal = (record: ISchool) => {
    setIsViewModalOpen(true);
    setSelectedRecord(record);
  };

  const filteredSchooling = schooling?.filter((report: ISchool) =>
    report.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const closeViewModal = () => {
    setIsViewModalOpen(false);
  };

  const [cuurentPayId, setCurrentPayId] = useState<string>("");

  const handlePaymentModel = (id: string) => {
    setShowPayModal(true);
    setCurrentPayId(id);
  };

  const handlePayment = async () => {
    const resp = await updateSchoolingPayment(cuurentPayId);
    if (resp) {
      setPaymentStatus(resp);
      setShowPayModal(false);
      toast.success("Payment Done Sucessfully");
      await schoolingFunction();
    }
  };

  const openModal = (event: any, record: ISchool) => {
    setIsModalOpen(true);
    setActionToPerform(event?.target?.name);
    setSelectedRecord(record);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setComments("");
  };

  const handleSchoolingReq = async (schoolingData: ISchool) => {
    const reqBody = {
      comment: comments,
    };
    const res = await updateSchoolingStatus(
      schoolingData.id,
      actionToPerform,
      comments
    );
    if (res?.res) {
      setReqAgain(!reqAgain);
      if (actionToPerform === "rejected") {
        toast.success("Schooling Request Rejected Successfully");
      } else {
        toast.success("Schooling  Request Approved Successfully");
      }
      schoolingFunction();
      closeModal();
    }
  };

  const handleComments = (comments: string) => {
    setComments(comments);
  };
  const currency = localStorage.getItem("currency");

  const handleKeyDown = (e: any) => {
    e.preventDefault(); // Prevent all key presses
  };

  return (
    <>
      <div className="py-5">
        <div className="relative flex md:flex-row flex-col md:items-center  mb-3 md:mb-6 gap-3">
          <input
            className="cursor-text w-full md:max-w-[310px] py-[10px] ps-10 pe-4  border border-[#D6D6D6] bg-[#ffffff] rounded-lg focus:outline-none placeholder:text-sm"
            type="search"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e?.target?.value)}
          />
          <span className="absolute top-3 left-3">
            <MagnifyingGlassIcon className="w-5 h-5 text-[#858585]" />
          </span>
          <div className="relative mb-3 md:mb-0 flex items-center gap-1 sm:gap-2 z-10">
            <ReactDatePicker
              className="cursor-pointer rounded-lg md:min-w-[230px] w-full bg-[#ffffff] border border-[#D6D6D6] text-sm py-3 px-2 placeholder:text-[#333] placeholder:font-medium"
              dateFormat="MMMM yyyy"
              placeholderText="Select Month"
              showMonthYearPicker
              selected={selectedDate}
              onChange={(date) => handleDateChange(date as Date)}
              preventOpenOnFocus
              onKeyDown={(e) => handleKeyDown(e)}
            />
            {selectedDate ? (
              <button className="absolute top-3 left-48" onClick={handleClear}>
                <XMarkIcon className="text-black w-5 h-5" />
              </button>
            ) : (
              <span className="absolute  top-3 left-48 pointer-events-none">
                <CalendarDaysIcon className="w-5 h-5 text-[#333]" />
              </span>
            )}
          </div>
        </div>
        {schooling && schooling?.length > 0 ? (
          <>
            <div
              className="Employees md:px-0 overflow-scroll overflow-x-hidden h-[calc(100vh-335px)]"
              ref={scrollToTopRef}
            >
              <table className="capitalize leaveMange w-full">
                <thead className="sticky top-0">
                  <tr>
                    <th></th>
                    <th>Employee Name</th>
                    <th>Amount</th>
                    <th>Payment Status</th>
                    <th>Date Applied</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {filteredSchooling?.map((req: ISchool, index: any) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td data-cell="Employee Name" className="flex gap-2">
                        <button
                          className={` text-[#378EB5] text-[10px] font-semibold ${
                            !req.attachments && "opacity-50 "
                          }`}
                          onClick={() => openViewModal(req)}
                          disabled={!req.attachments}
                        >
                          <PhotoIcon className="w-5 h-5 text-[#378EB5]" />
                        </button>

                        {req?.name}
                      </td>

                      <td data-cell="Amount">
                        {currency} {formatAmount(req?.schoolingAmount)}
                      </td>

                      <td data-cell="Payment">
                        <div className="flex justify-start">
                          {req?.paymentStatus}
                        </div>
                      </td>
                      <td data-cell="Date Applied">
                        <div className="flex justify-start">
                          {formatDateSpace(req?.createdAt)}
                        </div>
                      </td>

                      <td>
                        <div className="flex items-center justify-end gap-1">
                          {req?.paymentStatus === "Unpaid" ? (
                            <>
                              <button
                                className="btnApprove"
                                name="paid"
                                onClick={() => handlePaymentModel(req.id)}
                              >
                                Pay
                              </button>
                            </>
                          ) : (
                            ""
                          )}

                          {req.status === "pending" ? (
                            <>
                              <button
                                className="btnApprove"
                                name="accepted"
                                onClick={(event) => openModal(event, req)}
                              >
                                Approve
                              </button>
                              <button
                                className="btnReject"
                                name="rejected"
                                onClick={(event) => openModal(event, req)}
                              >
                                Reject
                              </button>
                            </>
                          ) : req?.status === "accepted" ? (
                            <span className="bg-[#23CDAF] text-white font-semibold text-[10px] rounded-[4px] px-2 py-0.5 uppercase">
                              Approved
                            </span>
                          ) : (
                            <span className="bg-[#333333] text-white font-semibold text-[10px] rounded-[4px] px-2 py-0.5 uppercase">
                              Rejected
                            </span>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <ApproveRejectModal
              isOpen={isModalOpen}
              onClose={closeModal}
              comments={comments}
              handleComments={handleComments}
              recordData={selectedRecord}
              actionToPerform={actionToPerform}
              sendRequest={handleSchoolingReq}
            />
          </>
        ) : (
          <div className="text-center flex flex-col justify-center mt-16">
            <span className="text-center flex justify-center mb-3">
              <img src="/images/no-data.png" alt="no have data" />{" "}
            </span>
            <p className="text-sm font-normal text-[#5C5C5C]">
              No School Allowance Request Available.
            </p>
          </div>
        )}
      </div>
      {showPayModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div ref={ref} className="bg-white rounded-lg p-5 w-[365px]">
            <h2 className="text-lg font-semibold mb-4">Confirm Payment </h2>
            <p className="mb-4">Are you sure you want to pay?</p>
            <div className="flex justify-end gap-2">
              <button
                className="bg-gray-300 text-gray-700 rounded px-4 py-2"
                onClick={() => setShowPayModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-[#378EB5] text-white rounded px-4 py-2"
                onClick={handlePayment}
              >
                Pay
              </button>
            </div>
          </div>
        </div>
      )}

      <ViewAttachments
        isOpen={isViewModalOpen}
        onClose={closeViewModal}
        attachments={selectedRecord?.attachments as string[]}
      />
    </>
  );
};

export default FuelAllowanceTable;
