import React from "react";

interface DeletePerformancelProps {
  isOpen: boolean;
  monthId: string;
  onClose: () => void;
  deleteModal: Function;
  monthName: string;
  year: number;
  userName: string;
}

const DeletePerformance: React.FC<DeletePerformancelProps> = ({
  isOpen,
  onClose,
  monthId,
  deleteModal,
  monthName,
  year,
  userName,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 z-20 flex items-center justify-center">
      <div className="modal-content bg-white rounded-lg shadow-md w-[355px]">
        <div className="flex justify-between items-center p-3">
          <h3 className="text-xl text-[#171717] font-semibold">
            Confirm Delete
          </h3>
          <span className="cursor-pointer w-6 h-6" onClick={onClose}>
            <img src="/images/Close-icon.svg" alt="close" />
          </span>
        </div>
        <hr />
        <p className="text-sm font-asap text-[#171717] p-3">
          Are you sure you want to delete the {monthName} {year} performance
          review of {userName}?
        </p>
        <hr />
        <div className="p-3 flex justify-end items-center gap-3">
          <button
            onClick={onClose}
            className="border border-[#D4D4D4] py-2.5 px-3 text-sm font-semibold text-[#171717] rounded-lg"
          >
            cancel
          </button>
          <button
            className="text-sm text-white font-semibold bg-[#B91C1C] py-2.5 px-3 border rounded-lg"
            onClick={() => {
              deleteModal();
              onClose();
            }}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};
export default DeletePerformance;
