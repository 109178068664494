// ModalComponent.tsx
import { XMarkIcon } from "@heroicons/react/24/solid";
import React, { useRef, useState } from "react";
import Select from "react-select";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { Asset } from "../../types/interfaces/IAssetsReq.interface";
import { Leave } from "../../types/interfaces/ILeaveRecords.interface";
import { MedicalRequest } from "../../types/interfaces/IMedicalAllowance.interface";
import { Salary } from "../../types/interfaces/ISalaryRecords.interface";
import { ISchool } from "../../types/interfaces/ISchooling";
import { WorkOnHoliday } from "../../types/interfaces/IWOH.interface";

interface Props {
  isOpen: boolean;
  onClose: () => void; // Assuming onClose is a function with no parameters
  comments?: string;
  handleComments?: (comment: string) => void;
  recordData?: Leave | Salary | Asset | WorkOnHoliday | MedicalRequest | any;
  actionToPerform?: string;
  sendRequest?: (recordData: any, paymentStatus: string) => void; //isn't accepting union of interfaces (Leave | Salary)
  isLeaveType?: boolean;
}

const ApproveRejectModal: React.FC<Props> = ({
  isOpen,
  onClose,
  comments,
  handleComments,
  recordData,
  actionToPerform,
  sendRequest,
  isLeaveType,
}) => {
  const ref: any = useRef();
  useOnClickOutside(ref, onClose);
  const [paymentStatus, setPaymentStatus] = useState({
    label: "Full Paid",
    value: "Full Paid",
  });

  const options = [
    { label: "Unpaid", value: "Unpaid" },
    { label: "Full Paid", value: "Full Paid" },
    { label: "Half Paid", value: "Half Paid" },
  ];

  const actionButton =
    actionToPerform === "accepted" || actionToPerform === "approved"
      ? "Approve"
      : "Reject";

  console.log(recordData, "recordData");
  return (
    <div
      className={`fixed inset-0 bg-gray-500 bg-opacity-75 z-20 flex items-center justify-center ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div ref={ref} className="bg-white rounded-lg shadow-md w-[690px]">
        <div className="flex justify-between items-center px-4 py-2">
          <h3 className="text-base font-semibold text-[#171717]">
            Add Comments
          </h3>
          <button onClick={onClose}>
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>
        <hr />
        <div className="p-3">
          <p className="text-base font-normal text-[#3333333] mb-3">
            Add comments before approve/reject
          </p>
          <textarea
            id="textareaField"
            name="textareaField"
            placeholder="Add comments"
            className="block text-base font-asap font-normal text-black p-3 h-28 bg-[#EBEBEB] rounded-md w-full"
            onChange={(event) => {
              handleComments && handleComments(event?.target?.value);
            }}
            value={comments}
          />
          {isLeaveType ? (
            <>
              <p className="text-base font-normal text-[#3333333] pt-5 mb-3">
                Select Payment Status
              </p>
              <div className={`relative`}>
                <Select
                  options={options}
                  value={paymentStatus}
                  onChange={(selectedOption) =>
                    setPaymentStatus(
                      selectedOption as { label: string; value: string }
                    )
                  }
                  className="rounded-lg border border-gray-300 "
                />
              </div>
            </>
          ) : null}
        </div>
        <div className="flex justify-end items-center gap-2 p-4">
          <button
            onClick={onClose}
            className="text-sm font-semibold text-[#333333] py-2.5 px-3 rounded-lg border-[#D4D4D4] border w-[72px] "
          >
            Cancel
          </button>
          <button
            className="text-sm font-semibold text-white bg-[#378EB5] py-2.5 px-3 rounded-lg w-[80px] h-10"
            onClick={() =>
              sendRequest &&
              sendRequest(
                recordData as Leave | Salary | ISchool,
                paymentStatus.label
              )
            }
          >
            {actionButton}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApproveRejectModal;
