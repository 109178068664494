import { TruckIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import FuelStatus from "../../components/Employeestatus/FuelStatus";
import FuelAllowanceTable from "../../components/Table/FuelAllowanceTable";
import { getLatestFuelPrice } from "../../services/fuelAllowance.service";

const FuelAllowance = () => {
  const [fuelPrice, setFuelPrice] = useState<any>();
  const currency = localStorage.getItem("currency");

  const getFuelPrice = async () => {
    const res: any = await getLatestFuelPrice();
    if (res?.res) {
      setFuelPrice(res?.res.latestFuelRate);
      console.log(fuelPrice);
    }
  };

  useEffect(() => {
    getFuelPrice();
  }, []);
  return (
    <>
      <div className="basis-full px-[15px] xl:px-[30px]">
        <FuelStatus
          heading="Fuel Allowance"
          leadText="Manage fuel allowance requests"
          StatusLogo={<TruckIcon className="w-9 h-9 text-[#33475F]" />}
          Subscription="Fuel Rate (per Ltr.)"
          price={fuelPrice}
          showFirst={true}
          showSecond={false}
          currency={currency}
          getFuelPrice={getFuelPrice}
        />
        <div className="basis-2/3">
          <FuelAllowanceTable />
        </div>
      </div>
    </>
  );
};

export default FuelAllowance;
