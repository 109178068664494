import { api } from "../utils/axios";

export interface IAddPerk {
  id?: string;
  userId?: string;
  amount: number;
  since: string;
  till: string;
  designation: string;
}
export const getPerkHistory = async (id: string) => {
  try {
    const response = await api.get(`/user/GetPerkHistory/${id}`);
    return response.data;
  } catch (e) {}
};

export const addUserPerk = async (data: IAddPerk) => {
  try {
    const response = await api.post(`/user/AddPerk`, data);
    return response.data;
  } catch (e) {}
};

export const deleteUserPerk = async (userId: string, id: string) => {
  try {
    const response = await api.delete(`/user/deletePerkRecord/${userId}/${id}`);
    return response.data;
  } catch (e) {}
};
