import { api } from "../utils/axios";

export const fetchMedicalAllowanceInfo = async () => {
  try {
    const { data, ok } = await api.get(`/medicalRequest/getPendingCount`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
export const fetchMedicalRequests = async (
  startDate?: string,
  endDate?: string,
  searchQuery?: string
) => {
  try {
    const { data, ok } = await api.get(
      `/medicalRequest/GetMedicalRequestsAdmin?${
        searchQuery ? `search=${searchQuery}` : ""
      }${startDate ? "&startDate=" + startDate + "&endDate=" + endDate : ""}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const updateMedicalRequestStatus = async (
  reqId: string,
  status: string,
  reqBody: { comment: string; approvedAmount?: number }
) => {
  try {
    if (status === "rejected") {
      delete reqBody.approvedAmount;
    }
    const { data, ok } = await api.put(
      `/medicalRequest/respondMedicalRequest/${reqId}/${status}`,
      reqBody
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    console.log("errror", error);
    return { res: null, err: error };
  }
};

export const exportMedicalCSV = async () => {
  try {
    const { data, ok } = await api.post(`/medicalRequest/ExportCSV`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const updateMedicalPaymentStatus = async (id: string) => {
  try {
    const { data, ok } = await api.patch(`/medicalRequest/markAsPaid/${id}`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
