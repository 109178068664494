import { createSlice } from "@reduxjs/toolkit";
import { storageKeys } from "../../constants/storage.constants";
import { UserData } from "../../types/interfaces/UserData";
interface Init {
  User: UserData | null;
  isLoggedIn: boolean;
  currency: string;
}
const initialState: Init = {
  User: {
    id: "",
    name: "",
    email: "",
    imageURL: "",
    isActive: false,
    role: "",
    isProfileCompleted: false,
  },
  currency: "",
  isLoggedIn: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthUser: (state, action) => {
      state.User = action.payload;
      state.isLoggedIn = true;
    },
    setCurrency: (state, action) => {
      state.currency = action.payload;
    },
    removeUser: (state) => {
      localStorage.setItem(storageKeys?.rememberMe, JSON.stringify(false));
      localStorage.removeItem(storageKeys?.user);
      localStorage.removeItem(storageKeys?.rfToken);
      localStorage.removeItem(storageKeys?.acToken);

      localStorage.removeItem(storageKeys?.user);
      localStorage.removeItem(storageKeys?.rfToken);
      localStorage.removeItem(storageKeys?.acToken);

      state.User = null;
      state.isLoggedIn = false;
    },
    loadUser: (state) => {
      const user =
        localStorage.getItem(storageKeys?.rememberMe) === "true"
          ? JSON.parse(localStorage.getItem(storageKeys?.user)!)
          : JSON.parse(localStorage.getItem(storageKeys?.user)!);
      if (user) {
        state.User = user;
        state.isLoggedIn = true;
      }
    },
  },
});

export const { setAuthUser, removeUser, loadUser, setCurrency } =
  authSlice.actions;

export default authSlice.reducer;
