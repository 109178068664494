import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ArrowUpTrayIcon, CalendarDaysIcon } from "@heroicons/react/24/solid";
import { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from "xlsx";
import { fetchAllFuels } from "../../services/fuelAllowance.service";
import { IFuel } from "../../types/interfaces/IFuelAllowance.interface";
import {
  formatAmount,
  formatDateMonth,
  formatDateSpace,
  formatDateYear,
} from "../../utils/dateFromatter";
import { scrollToTop } from "../../utils/tableScroller";

const FuelAllowanceTable = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [fuelRequests, setFuelRequests] = useState<IFuel>();
  const [reqAgain, setReqAgain] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [scrollOnDate, setScrollOnDate] = useState(false);
  let timeoutId: NodeJS.Timeout | null = null;
  let scrollToTopRef = useRef<HTMLTableSectionElement>(null);

  const getAllFuels = async (
    startDate?: string,
    endDate?: string,
    searchQuery?: string
  ) => {
    const res = await fetchAllFuels(startDate, endDate, searchQuery);
    if (res?.res) {
      setFuelRequests(res?.res as IFuel);
    }
  };

  const handleExportCSV = async () => {
    try {
      const res: any = await fetchAllFuels();

      if (res?.res) {
        const fuelRequests = res.res["Fuel Requests"] || [];

        const exportToXLSX = (
          filename: string,
          data: any[],
          sheetName: string
        ) => {
          const workBook = XLSX.utils.book_new();
          const worksheet = XLSX.utils.json_to_sheet(data);
          worksheet["!cols"] = [
            { wch: 25 },
            { wch: 10 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
          ];
          XLSX.utils.book_append_sheet(workBook, worksheet, sheetName);
          XLSX.writeFile(workBook, `${filename}.xlsx`);
        };

        const processRows = (data: any[]) => {
          return data.map((item, index) => ({
            Name: item.userName,
            Liters: String(item.fuelRequest.Liters),
            Amount: formatAmount(item.fuelRequest.Amount.toFixed(2)),
            RefillDate: item.fuelRequest.RefillDate,
            DateApplied: item.fuelRequest.DateApplied,
            Status: item.fuelRequest.Status,
            Month: item.fuelRequest.Month,
            Year: item.fuelRequest.Year,
          }));
        };

        const saveAsXlsx = () => {
          const formattedData = processRows(fuelRequests);
          exportToXLSX("FuelData", formattedData, "Fuel Data");
        };

        saveAsXlsx();
      } else {
        console.error("Error fetching fuel requests:", res?.error);
      }
    } catch (error) {
      console.error("Error fetching fuel requests:", error);
    }
  };

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
  };
  useEffect(() => {
    if (!selectedDate) return;
    setReqAgain(!reqAgain);
    setScrollOnDate(!scrollOnDate);
  }, [selectedDate]);

  useEffect(() => {
    if (searchQuery) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        if (selectedDate) {
          getAllFuels(
            formatDateMonth(selectedDate),
            formatDateYear(selectedDate),
            searchQuery
          );
        } else getAllFuels(undefined, undefined, searchQuery);
      }, 1000);
    } else if (selectedDate) {
      getAllFuels(formatDateMonth(selectedDate), formatDateYear(selectedDate));
    } else {
      getAllFuels();
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [reqAgain, searchQuery]);

  const handleClear = () => {
    setSelectedDate(undefined);
    setReqAgain(!reqAgain);
  };
  useEffect(() => {
    scrollToTop(scrollToTopRef);
  }, [searchQuery, scrollOnDate]);
  const currency = localStorage.getItem("currency");

  const handleKeyDown = (e: any) => {
    e.preventDefault();
  };

  return (
    <>
      <div className="py-5">
        <div className="relative flex md:flex-row flex-col md:items-center mb-3 md:mb-6 gap-3">
          <input
            className="cursor-text w-full md:max-w-[310px] py-[10px] ps-10 pe-4 border border-[#D6D6D6] bg-[#ffffff] rounded-lg focus:outline-none placeholder:text-sm"
            type="search"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e?.target?.value)}
          />
          <span className="absolute top-3 left-3">
            <MagnifyingGlassIcon className="w-5 h-5 text-[#858585]" />
          </span>
          <div className="relative flex items-center gap-1 sm:gap-2 z-10 w-full md:w-auto">
            <ReactDatePicker
              className="cursor-pointer rounded-lg md:min-w-[230px] w-full bg-[#ffffff] border border-[#D6D6D6] text-sm py-3 px-5 placeholder:text-[#333] placeholder:font-medium"
              dateFormat="MMMM yyyy"
              placeholderText="Select Month"
              showMonthYearPicker
              selected={selectedDate}
              onChange={(date) => handleDateChange(date as Date)}
              preventOpenOnFocus
              onKeyDown={(e) => handleKeyDown(e)}
            />
            {selectedDate ? (
              <button
                className="absolute top-3 right-4 items-center"
                onClick={handleClear}
              >
                <XMarkIcon className="text-black w-5 h-5  right-4" />
              </button>
            ) : (
              <span className="absolute top-3 right-4 pointer-events-none">
                <CalendarDaysIcon className="w-5 h-5 text-[#333]" />
              </span>
            )}
          </div>

          <button
            className="btnExport text-xs min-w-[90px] font-semibold text-[#378EB5] flex items-center w-[107px] ms-auto gap-1"
            onClick={() => handleExportCSV()}
          >
            <ArrowUpTrayIcon className="w-4 h-4" />
            Export CSV
          </button>
        </div>

        {fuelRequests &&
        fuelRequests?.["Fuel Requests"] &&
        fuelRequests?.["Fuel Requests"]?.length > 0 ? (
          <>
            <div
              className="Employees md:px-0 overflow-scroll overflow-x-hidden h-[calc(100vh-335px)]"
              ref={scrollToTopRef}
            >
              <table className="capitalize leaveMange w-full">
                <thead className="sticky top-0">
                  <tr>
                    <th></th>
                    <th>Employee Name</th>
                    <th>Refill Date</th>
                    <th>Quantity(ltr.)</th>
                    <th>Amount</th>
                    <th>Date Applied</th>
                  </tr>
                </thead>
                <tbody>
                  {fuelRequests?.["Fuel Requests"]?.map((fuel, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td data-cell="Employee Name">{fuel?.userName}</td>
                      <td data-cell="Refill Date">
                        {formatDateSpace(fuel?.fuelRequest?.RefillDate)}
                      </td>
                      <td data-cell="Quantity">{fuel?.fuelRequest?.Liters}</td>

                      <td data-cell="Amount">
                        {currency}
                        {"  "}{" "}
                        {formatAmount(fuel?.fuelRequest?.Amount.toFixed(2))}
                      </td>

                      <td data-cell="Date Applied">
                        <div className="flex justify-start">
                          {formatDateSpace(fuel?.fuelRequest?.DateApplied)}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div className="text-center flex flex-col justify-center mt-16">
            <span className="text-center flex justify-center mb-3">
              <img src="/images/no-data.png" alt="no have data" />{" "}
            </span>
            <p className="text-sm font-normal text-[#5C5C5C]">
              No Fuel Allowance Request Available.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default FuelAllowanceTable;
