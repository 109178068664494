import React, { useEffect, useState } from "react";
import SuggestionStatus from "../../components/suggestionItem/SuggestionApproved";
import SuggestionTable from "../../components/suggestionItem/SuggestionTable";
import { fetchSuggestionInfo } from "../../services/suggestion.service";
import { ITotalSuggestion } from "../../types/interfaces/ITotalSuggestion";
import { ISuggestion, SuggestionData } from "../../types/ISuggestion";

const Suggestion: React.FC = () => {
  const [suggestionData, setSuggestionData] = useState<
    SuggestionData | undefined
  >();
  const [openPopoverId, setOpenPopoverId] = useState<string | null>(null);
  const [openPopoverAssetId, setOpenPopoverAssetId] = useState<string | null>(
    null
  );
  const [selectedSuggestion, setSelectedSuggestion] = useState<string | null>(
    null
  );

  const [suggestionCount, setSuggestionCount] = useState<number>(0);
  const [approveCount, setApproveCount] = useState<number>(0);

  const [isOpenMainList, setIsOpenMainList] = useState(false);

  const fetchSuggestionData = async () => {
    try {
      const resp = await fetchSuggestionInfo();
      if (resp && "res" in resp && resp.res !== null) {
        const suggestionData = resp.res as ISuggestion;
        console.log("resp", resp);
        setSuggestionCount(suggestionData.suggestionCount);
        setApproveCount(suggestionData.approveCount);
        setSuggestionData(suggestionData.allSuggestions);
        console.log(suggestionData);
      } else {
        console.error("Error fetching suggestion data:", resp?.err);
      }
    } catch (error) {
      console.error("Error fetching suggestion data:", error);
    }
  };

  useEffect(() => {
    fetchSuggestionData();
  }, []);
  return (
    <>
      <div
        className="basis-full px-[15px] xl:px-[30px]"
        onClick={() => {
          if (openPopoverId) setOpenPopoverId(null);
          if (openPopoverAssetId) setOpenPopoverAssetId(null);
          if (isOpenMainList) setIsOpenMainList(false);
        }}
      >
        <div>
          <SuggestionStatus
            suggestionCount={suggestionCount}
            approveCount={approveCount}
          />
          <SuggestionTable
            getSuggestionInfo={suggestionData}
            openPopoverId={openPopoverId}
            setOpenPopoverId={setOpenPopoverId}
            openPopoverAssetId={openPopoverAssetId}
            setOpenPopoverAssetId={setOpenPopoverAssetId}
            selectedSuggestion={selectedSuggestion}
            setSelectedSuggestion={setSelectedSuggestion}
            fetchSuggestionData={fetchSuggestionData}
          />
        </div>
      </div>
    </>
  );
};

export default Suggestion;
