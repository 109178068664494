import { useMsal } from "@azure/msal-react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loginRequest } from "../../config/auth-config";
import { storageKeys } from "../../constants/storage.constants";
import { setAuthUser, setCurrency } from "../../features/Auth Slice/authSlice";
import { authLogin, getCurrency, signIn } from "../../services/auth.service";
import { store } from "../../store/store";
import { ILoginCredentials } from "../../types/interfaces/ILoginCredentials.interface";

const initFormCredentials = {
  email: "",
  password: "",
};
const initFormErrors = {
  emailError: "",
  passwordError: "",
  loginError: false,
};
const emailRegex = /^(?!.*\s)[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const passwordRegex = /.{8,32}$/;
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [mailIconSolid, setMailIconSolid] = useState(false);
  const [lockIconSolid, setLockIconSolid] = useState(false);
  const [loginFormCrdentials, setLoginFormCredentials] =
    useState<ILoginCredentials>(initFormCredentials);
  const [loginFormErrors, setLoginFormErrors] = useState(initFormErrors);

  const onSubmit = async () => {
    const res = await authLogin(loginFormCrdentials);
    if (res) {
      navigate("/");
      clearForm();
    } else setLoginFormErrors((prev) => ({ ...prev, loginError: true }));
  };

  const clearForm = () => {
    setLoginFormCredentials(initFormCredentials);
    setLoginFormErrors(initFormErrors);
  };

  const submitHandlerMSAL = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    try {
      console.log("Login Scope:", loginRequest.scopes);
      const token = await instance.loginPopup(loginRequest);
      localStorage.setItem(storageKeys?.acToken, token.accessToken);

      const data = await signIn();
      if (data) {
        const currency: any = await getCurrency();
        store.dispatch(setCurrency(currency.settings.currencyCode));
        if (data.userInfo.role === "admin") {
          localStorage.setItem(
            storageKeys?.user,
            JSON.stringify(data.userInfo)
          );
          localStorage.setItem("currency", currency.settings.currencyCode);

          dispatch(setAuthUser(data.userInfo));
          navigate("/");
        } else {
          toast.error("You are Unauthorized");
          await instance.logoutRedirect();
        }
      } else {
        toast.error("You are Unauthorized to Access");
        await instance.logoutRedirect();
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <>
      <div className="flex flex-row">
        <div className="basis-3/5 lg:block hidden">
          <div className="max-h-screen relative">
            <img
              className="h-screen w-full object-fill"
              src="/images/sign-in-img.png"
              alt="img"
            />
            <div className="absolute top-[111px] text-center w-full">
              <h2 className="text-5xl text-white font-semibold">
                Get yourself Managed
              </h2>
              <p className="text-xl text-[#D6D6D6] font-semibold font-asap">
                Welcome to Employees App
              </p>
            </div>
          </div>
        </div>
        <div className="basis-full lg:basis-2/5 rounded-r-md bg-[#FAFAFA]">
          <img
            className="py-[40px] lg:ps-[30px] px-[15px]"
            src="/images/EMPLOYEES.svg"
            alt="EMPLOYEES"
          />

          <img
            className="text-center mx-auto"
            src="/images/sign-in-logo.svg"
            alt="logo"
          />
          <div className="px-[30px]">
            <h2 className="text-[2rem] text-[#333] flex justify-center font-semibold py-7">
              Login to your account
            </h2>
            <button
              className="flex justify-center items-center bg-white border border-[#D6D6D6] rounded-lg px-3 py-[14px] w-full text-[#333] text-base font-semibold gap-2"
              onClick={(event) => submitHandlerMSAL(event)}
            >
              <img src="/images/Microsoft svg.svg" alt="Microsoft" />
              Continue with Microsoft
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
